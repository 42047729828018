(function () {
  'use strict';
  var t = this || self,
    e = function () {},
    i = function (t) {
      var e = typeof t;
      return 'object' != e ? e : t ? (Array.isArray(t) ? 'array' : e) : 'null';
    },
    s = function (t) {
      var e = i(t);
      return 'array' == e || ('object' == e && 'number' == typeof t.length);
    },
    a = function (t) {
      return 'function' == i(t);
    },
    n = function (t) {
      var e = typeof t;
      return ('object' == e && null != t) || 'function' == e;
    },
    r = function (t, e, i) {
      return t.call.apply(t.bind, arguments);
    },
    o = function (t, e, i) {
      if (!t) throw Error();
      if (2 < arguments.length) {
        var s = Array.prototype.slice.call(arguments, 2);
        return function () {
          var i = Array.prototype.slice.call(arguments);
          return Array.prototype.unshift.apply(i, s), t.apply(e, i);
        };
      }
      return function () {
        return t.apply(e, arguments);
      };
    },
    c = function (t, e, i) {
      return (c =
        Function.prototype.bind &&
        -1 != Function.prototype.bind.toString().indexOf('native code')
          ? r
          : o).apply(null, arguments);
    },
    h =
      Date.now ||
      function () {
        return +new Date();
      },
    u = function (e, i) {
      e = e.split('.');
      var s,
        a = t;
      e[0] in a || void 0 === a.execScript || a.execScript('var ' + e[0]);
      for (; e.length && (s = e.shift()); )
        e.length || void 0 === i
          ? (a = a[s] && a[s] !== Object.prototype[s] ? a[s] : (a[s] = {}))
          : (a[s] = i);
    },
    l = function (t, e) {
      function i() {}
      (i.prototype = e.prototype),
        (t.prototype = new i()),
        (t.prototype.constructor = t);
    },
    p = function (t) {
      return t;
    },
    d = function (t) {
      if (Error.captureStackTrace) Error.captureStackTrace(this, d);
      else {
        const t = Error().stack;
        t && (this.stack = t);
      }
      t && (this.message = String(t));
    };
  l(d, Error), (d.prototype.name = 'CustomError');
  var f = function (t, e) {
    for (var i = '', s = (t = t.split('%s')).length - 1, a = 0; a < s; a++)
      i += t[a] + (a < e.length ? e[a] : '%s');
    d.call(this, i + t[s]);
  };
  l(f, d), (f.prototype.name = 'AssertionError');
  var v,
    m,
    E = function (t, e) {
      throw new f(
        'Failure' + (t ? ': ' + t : ''),
        Array.prototype.slice.call(arguments, 1),
      );
    },
    S = function (t, e) {
      if (e)
        t = t
          .replace(y, '&amp;')
          .replace(b, '&lt;')
          .replace(g, '&gt;')
          .replace(C, '&quot;')
          .replace(_, '&#39;')
          .replace(N, '&#0;');
      else {
        if (!T.test(t)) return t;
        -1 != t.indexOf('&') && (t = t.replace(y, '&amp;')),
          -1 != t.indexOf('<') && (t = t.replace(b, '&lt;')),
          -1 != t.indexOf('>') && (t = t.replace(g, '&gt;')),
          -1 != t.indexOf('"') && (t = t.replace(C, '&quot;')),
          -1 != t.indexOf("'") && (t = t.replace(_, '&#39;')),
          -1 != t.indexOf('\0') && (t = t.replace(N, '&#0;'));
      }
      return t;
    },
    y = /&/g,
    b = /</g,
    g = />/g,
    C = /"/g,
    _ = /'/g,
    N = /\x00/g,
    T = /[\x00&<>"']/;
  t: {
    var I = t.navigator;
    if (I) {
      var A = I.userAgent;
      if (A) {
        v = A;
        break t;
      }
    }
    v = '';
  }
  var L = function () {
    this.Ha = '';
  };
  (L.prototype.za = !0),
    (L.prototype.ya = function () {
      return this.Ha;
    }),
    (L.prototype.toString = function () {
      return 'SafeStyle{' + this.Ha + '}';
    }),
    (L.prototype.I = function (t) {
      return (this.Ha = t), this;
    }),
    new L().I('');
  var w = function () {
    this.Ga = '';
  };
  (w.prototype.za = !0),
    (w.prototype.ya = function () {
      return this.Ga;
    }),
    (w.prototype.toString = function () {
      return 'SafeStyleSheet{' + this.Ga + '}';
    }),
    (w.prototype.I = function (t) {
      return (this.Ga = t), this;
    }),
    new w().I('');
  var k = function () {
    (this.W = ''), (this.gb = M), (this.oa = null);
  };
  (k.prototype.Ab = !0),
    (k.prototype.za = !0),
    (k.prototype.ya = function () {
      return this.W.toString();
    }),
    (k.prototype.toString = function () {
      return 'SafeHtml{' + this.W + '}';
    });
  var D = function (t) {
      return t instanceof k && t.constructor === k && t.gb === M
        ? t.W
        : (E(
            "expected object of type SafeHtml, got '" + t + "' of type " + i(t),
          ),
          'type_error:SafeHtml');
    },
    O = function (t) {
      if (t instanceof k) return t;
      var e = 'object' == typeof t,
        i = null;
      return (
        e && t.Ab && (i = t.oa),
        (t = S(e && t.za ? t.ya() : String(t))),
        new k().I(t, i)
      );
    },
    M = {};
  k.prototype.I = function (e, i) {
    if (void 0 === m) {
      var s = null,
        a = t.trustedTypes;
      if (a && a.createPolicy)
        try {
          s = a.createPolicy('goog#html', {
            createHTML: p,
            createScript: p,
            createScriptURL: p,
          });
        } catch (e) {
          t.console && t.console.error(e.message);
        }
      m = s;
    }
    return (this.W = (s = m) ? s.createHTML(e) : e), (this.oa = i), this;
  };
  var x = new k();
  (x.W =
    t.trustedTypes && t.trustedTypes.emptyHTML ? t.trustedTypes.emptyHTML : ''),
    (x.oa = 0);
  var R = (function (t) {
      let e,
        i = !1;
      return function () {
        return (
          i ||
            ((e = (function () {
              if ('undefined' == typeof document) return !1;
              var t = document.createElement('div'),
                e = document.createElement('div');
              return (
                e.appendChild(document.createElement('div')),
                t.appendChild(e),
                !!t.firstChild &&
                  ((e = t.firstChild.firstChild),
                  (t.innerHTML = D(x)),
                  !e.parentElement)
              );
            })()),
            (i = !0)),
          e
        );
      };
    })(),
    P = function (t, e, i) {
      this.reset(t, e, i, void 0, void 0);
    };
  P.prototype.ba = null;
  (P.prototype.reset = function (t, e, i, s, a) {
    'number' == typeof a || 0,
      (this.fb = s || h()),
      (this.C = t),
      (this.Fb = e),
      (this.Ua = i),
      delete this.ba;
  }),
    (P.prototype.La = function (t) {
      this.C = t;
    }),
    (P.prototype.getMessage = function () {
      return this.Fb;
    });
  var B = function (t) {
      (this.Gb = t), (this.S = this.ma = this.C = this.l = null);
    },
    G = function (t, e) {
      (this.name = t), (this.value = e);
    };
  G.prototype.toString = function () {
    return this.name;
  };
  var H = new G('SEVERE', 1e3),
    U = new G('WARNING', 900),
    V = new G('INFO', 800),
    j = new G('CONFIG', 700),
    F = [
      new G('OFF', 1 / 0),
      new G('SHOUT', 1200),
      H,
      U,
      V,
      j,
      new G('FINE', 500),
      new G('FINER', 400),
      new G('FINEST', 300),
      new G('ALL', 0),
    ],
    q = null;
  (B.prototype.getParent = function () {
    return this.l;
  }),
    (B.prototype.getChildren = function () {
      return this.ma || (this.ma = {}), this.ma;
    }),
    (B.prototype.La = function (t) {
      this.C = t;
    });
  var K = function (t) {
    return t.C
      ? t.C
      : t.l
        ? K(t.l)
        : (E('Root logger has no level set.'), null);
  };
  (B.prototype.log = function (t, e, i) {
    if (t.value >= K(this).value)
      for (
        a(e) && (e = e()),
          t = new P(t, String(e), this.Gb),
          i && (t.ba = i),
          i = this;
        i;

      ) {
        var s = i,
          n = t;
        if (s.S) for (var r = 0; (e = s.S[r]); r++) e(n);
        i = i.getParent();
      }
  }),
    (B.prototype.info = function (t, e) {
      this.log(V, t, e);
    });
  var W = {},
    Y = null,
    J = function () {
      Y || ((Y = new B('')), (W[''] = Y), Y.La(j));
    },
    Z = function () {
      return J(), Y;
    },
    z = function (t) {
      var e;
      if ((J(), !(e = W[t]))) {
        e = new B(t);
        var i = t.lastIndexOf('.'),
          s = t.substr(i + 1);
        ((i = z(t.substr(0, i))).getChildren()[s] = e), (e.l = i), (W[t] = e);
      }
      return e;
    },
    X = function (t) {
      Se && Se.log(U, t, void 0);
    },
    $ = function () {
      this.Za = h();
    },
    Q = null;
  ($.prototype.set = function (t) {
    this.Za = t;
  }),
    ($.prototype.reset = function () {
      this.set(h());
    }),
    ($.prototype.get = function () {
      return this.Za;
    });
  var tt = function (t) {
    (this.Mb = t || ''), Q || (Q = new $()), (this.Zb = Q);
  };
  (tt.prototype.Na = !0),
    (tt.prototype.cb = !0),
    (tt.prototype.Wb = !0),
    (tt.prototype.Vb = !0),
    (tt.prototype.eb = !1),
    (tt.prototype.Xb = !1);
  var et = function (t) {
      return 10 > t ? '0' + t : String(t);
    },
    it = function (t) {
      tt.call(this, t);
    };
  l(it, tt);
  var st = function () {
    (this.Nb = c(this.hb, this)),
      (this.ca = new it()),
      (this.ca.cb = !1),
      (this.ca.eb = !1),
      (this.Ta = this.ca.Na = !1),
      (this.pb = {});
  };
  st.prototype.hb = function (t) {
    if (!this.pb[t.Ua]) {
      var e = (function (t, e) {
          var i = [];
          if ((i.push(t.Mb, ' '), t.cb)) {
            var s = new Date(e.fb);
            i.push(
              '[',
              et(s.getFullYear() - 2e3) +
                et(s.getMonth() + 1) +
                et(s.getDate()) +
                ' ' +
                et(s.getHours()) +
                ':' +
                et(s.getMinutes()) +
                ':' +
                et(s.getSeconds()) +
                '.' +
                et(Math.floor(s.getMilliseconds() / 10)),
              '] ',
            );
          }
          return (
            t.Wb &&
              i.push(
                '[',
                (function (t, e) {
                  e = (t = (t.fb - e) / 1e3).toFixed(3);
                  var i = 0;
                  if (1 > t) i = 2;
                  else for (; 100 > t; ) i++, (t *= 10);
                  for (; 0 < i--; ) e = ' ' + e;
                  return e;
                })(e, t.Zb.get()),
                's] ',
              ),
            t.Vb && i.push('[', e.Ua, '] '),
            t.Xb && i.push('[', e.C.name, '] '),
            i.push(e.getMessage()),
            t.eb &&
              (e = e.ba) &&
              i.push('\n', e instanceof Error ? e.message : e.toString()),
            t.Na && i.push('\n'),
            i.join('')
          );
        })(this.ca, t),
        i = nt;
      if (i) {
        var s = (function (t) {
          if (t) {
            if (t.value >= H.value) return 'error';
            if (t.value >= U.value) return 'warn';
            if (t.value >= j.value) return 'log';
          }
          return 'debug';
        })(t.C);
        rt(i, s, e, t.ba);
      }
    }
  };
  var at = null,
    nt = t.console,
    rt = function (t, e, i, s) {
      t[e] ? t[e](i, s || '') : t.log(i, s || '');
    },
    ot = { dc: !0 },
    ct = function () {
      throw Error('Do not instantiate directly');
    };
  (ct.prototype.Pa = null),
    (ct.prototype.getContent = function () {
      return this.content;
    }),
    (ct.prototype.toString = function () {
      return this.content;
    });
  var ht = function () {
    ct.call(this);
  };
  l(ht, ct), (ht.prototype.Qa = ot);
  var ut,
    lt = class {
      constructor(t, e, i) {
        (this.Cb = i),
          (this.kb = t),
          (this.Qb = e),
          (this.ha = 0),
          (this.ea = null);
      }
      get() {
        let t;
        return (
          0 < this.ha
            ? (this.ha--, (t = this.ea), (this.ea = t.next), (t.next = null))
            : (t = this.kb()),
          t
        );
      }
      put(t) {
        this.Qb(t),
          this.ha < this.Cb && (this.ha++, (t.next = this.ea), (this.ea = t));
      }
    },
    pt = function (e) {
      t.setTimeout(function () {
        throw e;
      }, 0);
    },
    dt = function () {
      var e = t.MessageChannel;
      if (
        (void 0 === e &&
          'undefined' != typeof window &&
          window.postMessage &&
          window.addEventListener &&
          -1 == v.indexOf('Presto') &&
          (e = function () {
            var t,
              e,
              i =
                ((t = document),
                (e = 'IFRAME'),
                'application/xhtml+xml' === t.contentType &&
                  (e = e.toLowerCase()),
                t.createElement(e));
            (i.style.display = 'none'), document.documentElement.appendChild(i);
            var s = i.contentWindow;
            (i = s.document).open(), i.close();
            var a = 'callImmediate' + Math.random(),
              n =
                'file:' == s.location.protocol
                  ? '*'
                  : s.location.protocol + '//' + s.location.host;
            (i = c(function (t) {
              ('*' != n && t.origin != n) ||
                t.data != a ||
                this.port1.onmessage();
            }, this)),
              s.addEventListener('message', i, !1),
              (this.port1 = {}),
              (this.port2 = {
                postMessage: function () {
                  s.postMessage(a, n);
                },
              });
          }),
        void 0 !== e && -1 == v.indexOf('Trident') && -1 == v.indexOf('MSIE'))
      ) {
        var i = new e(),
          s = {},
          a = s;
        return (
          (i.port1.onmessage = function () {
            if (void 0 !== s.next) {
              var t = (s = s.next).Oa;
              (s.Oa = null), t();
            }
          }),
          function (t) {
            (a.next = { Oa: t }), (a = a.next), i.port2.postMessage(0);
          }
        );
      }
      return function (e) {
        t.setTimeout(e, 0);
      };
    },
    ft = function () {
      this.ka = this.P = null;
    },
    vt = new lt(
      function () {
        return new mt();
      },
      function (t) {
        t.reset();
      },
      100,
    );
  (ft.prototype.add = function (t, e) {
    var i = vt.get();
    i.set(t, e), this.ka ? (this.ka.next = i) : (this.P = i), (this.ka = i);
  }),
    (ft.prototype.remove = function () {
      var t = null;
      return (
        this.P &&
          ((t = this.P),
          (this.P = this.P.next),
          this.P || (this.ka = null),
          (t.next = null)),
        t
      );
    });
  var mt = function () {
    this.next = this.scope = this.sa = null;
  };
  (mt.prototype.set = function (t, e) {
    (this.sa = t), (this.scope = e), (this.next = null);
  }),
    (mt.prototype.reset = function () {
      this.next = this.scope = this.sa = null;
    });
  var Et,
    St = function (t, e) {
      Et || yt(), bt || (Et(), (bt = !0)), gt.add(t, e);
    },
    yt = function () {
      if (t.Promise && t.Promise.resolve) {
        var e = t.Promise.resolve(void 0);
        Et = function () {
          e.then(Ct);
        };
      } else
        Et = function () {
          var e = Ct;
          !a(t.setImmediate) ||
          (t.Window &&
            t.Window.prototype &&
            -1 == v.indexOf('Edge') &&
            t.Window.prototype.setImmediate == t.setImmediate)
            ? (ut || (ut = dt()), ut(e))
            : t.setImmediate(e);
        };
    },
    bt = !1,
    gt = new ft(),
    Ct = function () {
      for (var t; (t = gt.remove()); ) {
        try {
          t.sa.call(t.scope);
        } catch (t) {
          pt(t);
        }
        vt.put(t);
      }
      bt = !1;
    },
    _t = function (t) {
      if (
        ((this.g = 0),
        (this.$a = void 0),
        (this.F = this.s = this.l = null),
        (this.da = this.ra = !1),
        t != e)
      )
        try {
          var i = this;
          t.call(
            void 0,
            function (t) {
              Dt(i, 2, t);
            },
            function (t) {
              if (!(t instanceof Ht))
                try {
                  if (t instanceof Error) throw t;
                  throw Error('Promise rejected.');
                } catch (t) {}
              Dt(i, 3, t);
            },
          );
        } catch (t) {
          Dt(this, 3, t);
        }
    },
    Nt = function () {
      (this.next = this.context = this.L = this.U = this.B = null),
        (this.Z = !1);
    };
  Nt.prototype.reset = function () {
    (this.context = this.L = this.U = this.B = null), (this.Z = !1);
  };
  var Tt = new lt(
      function () {
        return new Nt();
      },
      function (t) {
        t.reset();
      },
      100,
    ),
    It = function (t, e, i) {
      var s = Tt.get();
      return (s.U = t), (s.L = e), (s.context = i), s;
    },
    At = function () {
      var t,
        e,
        i = new _t(function (i, s) {
          (t = i), (e = s);
        });
      return new Ut(i, t, e);
    };
  (_t.prototype.then = function (t, e, i) {
    return kt(this, a(t) ? t : null, a(e) ? e : null, i);
  }),
    (_t.prototype.$goog_Thenable = !0),
    (_t.prototype.cancel = function (t) {
      if (0 == this.g) {
        var e = new Ht(t);
        St(function () {
          Lt(this, e);
        }, this);
      }
    });
  var Lt = function (t, e) {
      if (0 == t.g)
        if (t.l) {
          var i = t.l;
          if (i.s) {
            for (
              var s = 0, a = null, n = null, r = i.s;
              r && (r.Z || (s++, r.B == t && (a = r), !(a && 1 < s)));
              r = r.next
            )
              a || (n = r);
            a &&
              (0 == i.g && 1 == s
                ? Lt(i, e)
                : (n
                    ? ((s = n).next == i.F && (i.F = s), (s.next = s.next.next))
                    : xt(i),
                  Rt(i, a, 3, e)));
          }
          t.l = null;
        } else Dt(t, 3, e);
    },
    wt = function (t, e) {
      t.s || (2 != t.g && 3 != t.g) || Mt(t),
        t.F ? (t.F.next = e) : (t.s = e),
        (t.F = e);
    },
    kt = function (t, e, i, s) {
      var a = It(null, null, null);
      return (
        (a.B = new _t(function (t, n) {
          (a.U = e
            ? function (i) {
                try {
                  var a = e.call(s, i);
                  t(a);
                } catch (t) {
                  n(t);
                }
              }
            : t),
            (a.L = i
              ? function (e) {
                  try {
                    var a = i.call(s, e);
                    void 0 === a && e instanceof Ht ? n(e) : t(a);
                  } catch (t) {
                    n(t);
                  }
                }
              : n);
        })),
        (a.B.l = t),
        wt(t, a),
        a.B
      );
    };
  (_t.prototype.$b = function (t) {
    (this.g = 0), Dt(this, 2, t);
  }),
    (_t.prototype.ac = function (t) {
      (this.g = 0), Dt(this, 3, t);
    });
  var Dt = function (t, i, s) {
      if (0 == t.g) {
        t === s &&
          ((i = 3), (s = new TypeError('Promise cannot resolve to itself'))),
          (t.g = 1);
        t: {
          var r = s,
            o = t.$b,
            c = t.ac;
          if (r instanceof _t) {
            wt(r, It(o || e, c || null, t));
            var h = !0;
          } else {
            if (r)
              try {
                var u = !!r.$goog_Thenable;
              } catch (t) {
                u = !1;
              }
            else u = !1;
            if (u) r.then(o, c, t), (h = !0);
            else {
              if (n(r))
                try {
                  var l = r.then;
                  if (a(l)) {
                    Ot(r, l, o, c, t), (h = !0);
                    break t;
                  }
                } catch (e) {
                  c.call(t, e), (h = !0);
                  break t;
                }
              h = !1;
            }
          }
        }
        h ||
          ((t.$a = s),
          (t.g = i),
          (t.l = null),
          Mt(t),
          3 != i || s instanceof Ht || Bt(t, s));
      }
    },
    Ot = function (t, e, i, s, a) {
      var n = !1,
        r = function (t) {
          n || ((n = !0), s.call(a, t));
        };
      try {
        e.call(
          t,
          function (t) {
            n || ((n = !0), i.call(a, t));
          },
          r,
        );
      } catch (t) {
        r(t);
      }
    },
    Mt = function (t) {
      t.ra || ((t.ra = !0), St(t.nb, t));
    },
    xt = function (t) {
      var e = null;
      return (
        t.s && ((e = t.s), (t.s = e.next), (e.next = null)),
        t.s || (t.F = null),
        e
      );
    };
  _t.prototype.nb = function () {
    for (var t; (t = xt(this)); ) Rt(this, t, this.g, this.$a);
    this.ra = !1;
  };
  var Rt = function (t, e, i, s) {
      if (3 == i && e.L && !e.Z) for (; t && t.da; t = t.l) t.da = !1;
      if (e.B) (e.B.l = null), Pt(e, i, s);
      else
        try {
          e.Z ? e.U.call(e.context) : Pt(e, i, s);
        } catch (t) {
          Gt.call(null, t);
        }
      Tt.put(e);
    },
    Pt = function (t, e, i) {
      2 == e ? t.U.call(t.context, i) : t.L && t.L.call(t.context, i);
    },
    Bt = function (t, e) {
      (t.da = !0),
        St(function () {
          t.da && Gt.call(null, e);
        });
    },
    Gt = pt,
    Ht = function (t) {
      d.call(this, t);
    };
  l(Ht, d), (Ht.prototype.name = 'cancel');
  var Ut = function (t, e, i) {
      (this.N = t), (this.resolve = e), (this.reject = i);
    },
    Vt = function () {
      (this.aa = this.aa), (this.ia = this.ia);
    };
  (Vt.prototype.aa = !1),
    (Vt.prototype.pa = function () {
      this.aa || ((this.aa = !0), this.qa());
    }),
    (Vt.prototype.qa = function () {
      if (this.ia) for (; this.ia.length; ) this.ia.shift()();
    });
  var jt = function (e) {
    e.D = (function (e, i) {
      if (!a(e)) {
        if (!e || 'function' != typeof e.handleEvent)
          throw Error('Invalid listener argument');
        e = c(e.handleEvent, e);
      }
      return 2147483647 < Number(i) ? -1 : t.setTimeout(e, i || 0);
    })(() => {
      (e.D = null), e.O && !e.V && ((e.O = !1), jt(e));
    }, e.Bb);
    const i = e.$;
    (e.$ = null), e.Db.apply(null, i);
  };
  class Ft extends Vt {
    constructor(t, e, i) {
      super(),
        (this.Db = null != i ? t.bind(i) : t),
        (this.Bb = e),
        (this.$ = null),
        (this.O = !1),
        (this.V = 0),
        (this.D = null);
    }
    qb(t) {
      (this.$ = arguments), this.D || this.V ? (this.O = !0) : jt(this);
    }
    stop() {
      this.D &&
        (t.clearTimeout(this.D),
        (this.D = null),
        (this.O = !1),
        (this.$ = null));
    }
    pause() {
      this.V++;
    }
    resume() {
      this.V--, this.V || !this.O || this.D || ((this.O = !1), jt(this));
    }
    qa() {
      super.qa(), this.stop();
    }
  }
  var qt = function (t) {
      return (
        !!t && void 0 !== t.currentBreakClipTime && void 0 !== t.breakClipId
      );
    },
    Kt = function (t) {
      if (!t.f)
        for (var e = 0, i = t.b.media; e < i.length; e++)
          if (!i[e].idleReason) {
            (t.f = i[e]), t.f.addUpdateListener(t.Eb.bind(t));
            break;
          }
    },
    Wt = function (t, e) {
      (t.b = e),
        e.addMediaListener(t.Va.bind(t)),
        e.addUpdateListener(t.Ka.bind(t)),
        Kt(t),
        t.A();
    },
    Yt = (function (t) {
      function e(t) {
        this.content = t;
      }
      return (
        (e.prototype = t.prototype),
        function (t, i) {
          return (t = new e(String(t))), void 0 !== i && (t.Pa = i), t;
        }
      );
    })(ht),
    Jt = {
      '\0': '&#0;',
      '\t': '&#9;',
      '\n': '&#10;',
      '\v': '&#11;',
      '\f': '&#12;',
      '\r': '&#13;',
      ' ': '&#32;',
      '"': '&quot;',
      '&': '&amp;',
      "'": '&#39;',
      '-': '&#45;',
      '/': '&#47;',
      '<': '&lt;',
      '=': '&#61;',
      '>': '&gt;',
      '`': '&#96;',
      '': '&#133;',
      ' ': '&#160;',
      '\u2028': '&#8232;',
      '\u2029': '&#8233;',
    },
    Zt = function (t) {
      return Jt[t];
    },
    zt = /[\x00\x22\x27\x3c\x3e]/g,
    Xt = /<(?:!|\/?([a-zA-Z][a-zA-Z0-9:\-]*))(?:[^>'"]|"[^"]*"|'[^']*')*>/g,
    $t = /</g,
    Qt = function (t) {
      return (
        (t = t && t.cc)
          ? (null != t && t.Qa === ot
              ? ((t = t.getContent()),
                (t = String(t).replace(Xt, '').replace($t, '&lt;')),
                (t = String(t).replace(zt, Zt)))
              : ((t = String(t)), (t = S(t, void 0))),
            (t = ' nonce="' + t + '"'))
          : (t = ''),
        Yt(
          '<style' +
            t +
            '>.cast_caf_state_c {fill: var(--connected-color, #4285f4);}.cast_caf_state_d {fill: var(--disconnected-color, #7d7d7d);}.cast_caf_state_h {opacity: 0;}</style><svg x="0px" y="0px" width="100%" height="100%" viewBox="0 0 24 24"><g><path id="cast_caf_icon_arch0" class="cast_caf_status_d" d="M1,18 L1,21 L4,21 C4,19.3 2.66,18 1,18 L1,18 Z"/><path id="cast_caf_icon_arch1" class="cast_caf_status_d" d="M1,14 L1,16 C3.76,16 6,18.2 6,21 L8,21 C8,17.13 4.87,14 1,14 L1,14 Z"/><path id="cast_caf_icon_arch2" class="cast_caf_status_d" d="M1,10 L1,12 C5.97,12 10,16.0 10,21 L12,21 C12,14.92 7.07,10 1,10 L1,10 Z"/><path id="cast_caf_icon_box" class="cast_caf_status_d" d="M21,3 L3,3 C1.9,3 1,3.9 1,5 L1,8 L3,8 L3,5 L21,5 L21,19 L14,19 L14,21 L21,21 C22.1,21 23,20.1 23,19 L23,5 C23,3.9 22.1,3 21,3 L21,3 Z"/><path id="cast_caf_icon_boxfill" class="cast_caf_state_h" d="M5,7 L5,8.63 C8,8.6 13.37,14 13.37,17 L19,17 L19,7 Z"/></g></svg>',
        )
      );
    };
  (Qt.ec = 'cast.framework.CastButtonTemplate.icon'),
    u('cast.framework.VERSION', '1.0.14'),
    u('cast.framework.LoggerLevel', {
      DEBUG: 0,
      INFO: 800,
      WARNING: 900,
      ERROR: 1e3,
      NONE: 1500,
    }),
    u('cast.framework.CastState', {
      NO_DEVICES_AVAILABLE: 'NO_DEVICES_AVAILABLE',
      NOT_CONNECTED: 'NOT_CONNECTED',
      CONNECTING: 'CONNECTING',
      CONNECTED: 'CONNECTED',
    }),
    u('cast.framework.SessionState', {
      NO_SESSION: 'NO_SESSION',
      SESSION_STARTING: 'SESSION_STARTING',
      SESSION_STARTED: 'SESSION_STARTED',
      SESSION_START_FAILED: 'SESSION_START_FAILED',
      SESSION_ENDING: 'SESSION_ENDING',
      SESSION_ENDED: 'SESSION_ENDED',
      SESSION_RESUMED: 'SESSION_RESUMED',
    }),
    u('cast.framework.CastContextEventType', {
      CAST_STATE_CHANGED: 'caststatechanged',
      SESSION_STATE_CHANGED: 'sessionstatechanged',
    }),
    u('cast.framework.SessionEventType', {
      APPLICATION_STATUS_CHANGED: 'applicationstatuschanged',
      APPLICATION_METADATA_CHANGED: 'applicationmetadatachanged',
      ACTIVE_INPUT_STATE_CHANGED: 'activeinputstatechanged',
      VOLUME_CHANGED: 'volumechanged',
      MEDIA_SESSION: 'mediasession',
    }),
    u('cast.framework.RemotePlayerEventType', {
      ANY_CHANGE: 'anyChanged',
      IS_CONNECTED_CHANGED: 'isConnectedChanged',
      IS_MEDIA_LOADED_CHANGED: 'isMediaLoadedChanged',
      QUEUE_DATA_CHANGED: 'queueDataChanged',
      VIDEO_INFO_CHANGED: 'videoInfoChanged',
      DURATION_CHANGED: 'durationChanged',
      CURRENT_TIME_CHANGED: 'currentTimeChanged',
      IS_PAUSED_CHANGED: 'isPausedChanged',
      VOLUME_LEVEL_CHANGED: 'volumeLevelChanged',
      CAN_CONTROL_VOLUME_CHANGED: 'canControlVolumeChanged',
      IS_MUTED_CHANGED: 'isMutedChanged',
      CAN_PAUSE_CHANGED: 'canPauseChanged',
      CAN_SEEK_CHANGED: 'canSeekChanged',
      DISPLAY_NAME_CHANGED: 'displayNameChanged',
      STATUS_TEXT_CHANGED: 'statusTextChanged',
      TITLE_CHANGED: 'titleChanged',
      DISPLAY_STATUS_CHANGED: 'displayStatusChanged',
      MEDIA_INFO_CHANGED: 'mediaInfoChanged',
      IMAGE_URL_CHANGED: 'imageUrlChanged',
      PLAYER_STATE_CHANGED: 'playerStateChanged',
      IS_PLAYING_BREAK_CHANGED: 'isPlayingBreakChanged',
      NUMBER_BREAK_CLIPS_CHANGED: 'numberBreakClipsChanged',
      CURRENT_BREAK_CLIP_NUMBER_CHANGED: 'currentBreakClipNumberChanged',
      CURRENT_BREAK_TIME_CHANGED: 'currentBreakTimeChanged',
      CURRENT_BREAK_CLIP_TIME_CHANGED: 'currentBreakClipTimeChanged',
      BREAK_ID_CHANGED: 'breakIdChanged',
      BREAK_CLIP_ID_CHANGED: 'breakClipIdChanged',
      WHEN_SKIPPABLE_CHANGED: 'whenSkippableChanged',
      LIVE_SEEKABLE_RANGE_CHANGED: 'liveSeekableRangeChanged',
    }),
    u('cast.framework.ActiveInputState', {
      ACTIVE_INPUT_STATE_UNKNOWN: -1,
      ACTIVE_INPUT_STATE_NO: 0,
      ACTIVE_INPUT_STATE_YES: 1,
    });
  var te = function (t) {
    Z().La(
      (function (t) {
        if (!q) {
          q = {};
          for (var e, i = 0; (e = F[i]); i++) (q[e.value] = e), (q[e.name] = e);
        }
        if (t in q) return q[t];
        for (i = 0; i < F.length; ++i) if ((e = F[i]).value <= t) return e;
        return null;
      })(t),
    );
  };
  if ((u('cast.framework.setLoggerLevel', te), at || (at = new st()), at)) {
    var ee = at;
    if (1 != ee.Ta) {
      var ie = Z(),
        se = ee.Nb;
      ie.S || (ie.S = []), ie.S.push(se), (ee.Ta = !0);
    }
  }
  te(1e3);
  var ae = class {
    constructor(t) {
      this.type = t;
    }
  };
  u('cast.framework.EventData', ae);
  var ne = class extends ae {
    constructor(t) {
      super('activeinputstatechanged'), (this.activeInputState = t);
    }
  };
  u('cast.framework.ActiveInputStateEventData', ne);
  var re = class {
    constructor(t) {
      (this.applicationId = t.appId),
        (this.name = t.displayName),
        (this.images = t.appImages),
        (this.namespaces = this.Ea(t.namespaces || []));
    }
    Ea(t) {
      return t.map((t) => t.name);
    }
  };
  u('cast.framework.ApplicationMetadata', re);
  var oe = class extends ae {
    constructor(t) {
      super('applicationmetadatachanged'), (this.metadata = t);
    }
  };
  u('cast.framework.ApplicationMetadataEventData', oe);
  var ce = class extends ae {
    constructor(t) {
      super('applicationstatuschanged'), (this.status = t);
    }
  };
  u('cast.framework.ApplicationStatusEventData', ce);
  const he = z('cast.framework.EventTarget');
  var ue = class {
      constructor() {
        this.T = {};
      }
      addEventListener(t, e) {
        t in this.T || (this.T[t] = []),
          (t = this.T[t]).includes(e) || t.push(e);
      }
      removeEventListener(t, e) {
        0 <= (e = (t = this.T[t] || []).indexOf(e)) && t.splice(e, 1);
      }
      dispatchEvent(t) {
        t &&
          t.type &&
          (this.T[t.type] || []).slice().forEach((e) => {
            try {
              e(t);
            } catch (e) {
              he &&
                he.log(H, 'Handler for ' + t.type + ' event failed: ' + e, e);
            }
          });
      }
    },
    le = class {
      constructor(t) {
        (t = t || {}),
          (this.receiverApplicationId = t.receiverApplicationId || null),
          (this.resumeSavedSession =
            void 0 === t.resumeSavedSession || t.resumeSavedSession),
          (this.autoJoinPolicy =
            void 0 !== t.autoJoinPolicy
              ? t.autoJoinPolicy
              : chrome.cast.AutoJoinPolicy.TAB_AND_ORIGIN_SCOPED),
          (this.language = t.language || null),
          (this.androidReceiverCompatible = t.androidReceiverCompatible || !1),
          (this.credentialsData = t.credentialsData || null);
      }
    };
  u('cast.framework.CastOptions', le);
  var pe = class extends ae {
    constructor(t) {
      super('mediasession'), (this.mediaSession = t);
    }
  };
  u('cast.framework.MediaSessionEventData', pe);
  var de = class extends ae {
    constructor(t, e) {
      super('volumechanged'), (this.volume = t), (this.isMute = e);
    }
  };
  u('cast.framework.VolumeEventData', de);
  var fe = function (t, e) {
      (t.Ya = e),
        !e.volume ||
          (t.i && t.i.muted == e.volume.muted && t.i.level == e.volume.level) ||
          ((t.i = e.volume), t.h.dispatchEvent(new de(t.i.level, t.i.muted))),
        t.fa != e.isActiveInput &&
          ((t.fa = e.isActiveInput),
          (e = t.fa),
          t.h.dispatchEvent(new ne(null == e ? -1 : e ? 1 : 0)));
    },
    ve = class {
      constructor(t, e) {
        (this.h = new ue()),
          (this.g = e),
          (this.c = t),
          (this.ab = t.sessionId),
          (this.X = t.statusText),
          (this.Ya = t.receiver),
          (this.i = t.receiver.volume),
          (this.ga = new re(t)),
          (this.fa = t.receiver.isActiveInput),
          this.c.addMediaListener(this.Ca.bind(this)),
          (function (t) {
            const e = t.c.loadMedia.bind(t.c);
            t.c.loadMedia = (i, s, a) => {
              e(
                i,
                (e) => {
                  s && s(e), t.Ca(e);
                },
                a,
              );
            };
            const i = t.c.queueLoad.bind(t.c);
            t.c.queueLoad = (e, s, a) => {
              i(
                e,
                (e) => {
                  s && s(e), t.Ca(e);
                },
                a,
              );
            };
          })(this);
      }
      addEventListener(t, e) {
        this.h.addEventListener(t, e);
      }
      removeEventListener(t, e) {
        this.h.removeEventListener(t, e);
      }
      yb() {
        return this.c;
      }
      xb() {
        return this.ab;
      }
      xa() {
        return this.g;
      }
      ub() {
        return this.Ya;
      }
      sb() {
        return this.ga;
      }
      tb() {
        return this.X;
      }
      rb() {
        var t = this.fa;
        return null == t ? -1 : t ? 1 : 0;
      }
      Sa(t) {
        'SESSION_ENDED' != this.g &&
          (t ? this.c.stop(e, e) : this.c.leave(e, e));
      }
      setVolume(t) {
        const e = At(),
          i = Promise.resolve(e.N);
        return (
          this.i && ((this.i.level = t), (this.i.muted = !1)),
          this.c.setReceiverVolumeLevel(
            t,
            () => e.resolve(),
            (t) => e.reject(t.code),
          ),
          i
        );
      }
      zb() {
        return this.i ? this.i.level : null;
      }
      Tb(t) {
        const e = At(),
          i = Promise.resolve(e.N);
        return (
          this.i && (this.i.muted = t),
          this.c.setReceiverMuted(
            t,
            () => e.resolve(),
            (t) => e.reject(t.code),
          ),
          i
        );
      }
      isMute() {
        return this.i ? this.i.muted : null;
      }
      sendMessage(t, e) {
        const i = At(),
          s = Promise.resolve(i.N);
        return (
          this.c.sendMessage(
            t,
            e,
            () => i.resolve(),
            (t) => i.reject(t.code),
          ),
          s
        );
      }
      addMessageListener(t, e) {
        this.c.addMessageListener(t, e);
      }
      removeMessageListener(t, e) {
        this.c.removeMessageListener(t, e);
      }
      loadMedia(t) {
        const e = At(),
          i = Promise.resolve(e.N);
        return (
          this.c.loadMedia(
            t,
            () => {
              e.resolve();
            },
            (t) => {
              e.reject(t.code);
            },
          ),
          i
        );
      }
      ua() {
        t: {
          var t = this.c;
          if (t.media)
            for (let e of t.media)
              if (!e.idleReason) {
                t = e;
                break t;
              }
          t = null;
        }
        return t;
      }
      Ca(t) {
        t.media && this.h.dispatchEvent(new pe(t));
      }
      Ea(t) {
        return t.map((t, e) => e.name);
      }
    };
  u('cast.framework.CastSession', ve),
    (ve.prototype.getMediaSession = ve.prototype.ua),
    (ve.prototype.loadMedia = ve.prototype.loadMedia),
    (ve.prototype.removeMessageListener = ve.prototype.removeMessageListener),
    (ve.prototype.addMessageListener = ve.prototype.addMessageListener),
    (ve.prototype.sendMessage = ve.prototype.sendMessage),
    (ve.prototype.isMute = ve.prototype.isMute),
    (ve.prototype.setMute = ve.prototype.Tb),
    (ve.prototype.getVolume = ve.prototype.zb),
    (ve.prototype.setVolume = ve.prototype.setVolume),
    (ve.prototype.endSession = ve.prototype.Sa),
    (ve.prototype.getActiveInputState = ve.prototype.rb),
    (ve.prototype.getApplicationStatus = ve.prototype.tb),
    (ve.prototype.getApplicationMetadata = ve.prototype.sb),
    (ve.prototype.getCastDevice = ve.prototype.ub),
    (ve.prototype.getSessionState = ve.prototype.xa),
    (ve.prototype.getSessionId = ve.prototype.xb),
    (ve.prototype.getSessionObj = ve.prototype.yb),
    (ve.prototype.removeEventListener = ve.prototype.removeEventListener),
    (ve.prototype.addEventListener = ve.prototype.addEventListener);
  var me = class extends ae {
    constructor(t) {
      super('caststatechanged'), (this.castState = t);
    }
  };
  u('cast.framework.CastStateEventData', me);
  var Ee = class extends ae {
    constructor(t, e, i) {
      super('sessionstatechanged'),
        (this.session = t),
        (this.sessionState = e),
        (this.errorCode = void 0 !== i ? i : null);
    }
  };
  u('cast.framework.SessionStateEventData', Ee);
  const Se = z('cast.framework.CastContext');
  var ye = function (t) {
      if (!t.j || !t.j.receiverApplicationId)
        throw Error('Missing application id in cast options');
      var i = new chrome.cast.SessionRequest(t.j.receiverApplicationId);
      t.j.language && (i.language = t.j.language),
        (i.androidReceiverCompatible = t.j.androidReceiverCompatible),
        (i.credentialsData = t.j.credentialsData),
        (i = new chrome.cast.ApiConfig(
          i,
          t.bb.bind(t),
          t.Pb.bind(t),
          t.j.autoJoinPolicy,
        )),
        chrome.cast.initialize(i, e, e),
        t.Ba || chrome.cast.addReceiverActionListener(t.Ob.bind(t)),
        (t.Ba = !0);
    },
    be = function (t) {
      let e = 'NO_DEVICES_AVAILABLE';
      switch (t.m) {
        case 'SESSION_STARTING':
        case 'SESSION_ENDING':
          e = 'CONNECTING';
          break;
        case 'SESSION_STARTED':
        case 'SESSION_RESUMED':
          e = 'CONNECTED';
          break;
        case 'NO_SESSION':
        case 'SESSION_ENDED':
        case 'SESSION_START_FAILED':
          e = t.Ja ? 'NOT_CONNECTED' : 'NO_DEVICES_AVAILABLE';
          break;
        default:
          X('Unexpected session state: ' + t.m);
      }
      e !== t.R && ((t.R = e), t.h.dispatchEvent(new me(e)));
    },
    ge = function (t, e, i) {
      e == t.m
        ? 'SESSION_START_FAILED' == e && t.h.dispatchEvent(new Ee(t.b, t.m, i))
        : ((t.m = e),
          t.b && (t.b.g = t.m),
          t.h.dispatchEvent(new Ee(t.b, t.m, i)),
          be(t));
    },
    Ce = class {
      constructor() {
        (this.h = new ue()),
          (this.Ba = !1),
          (this.j = null),
          (this.Ja = !1),
          (this.R = 'NO_DEVICES_AVAILABLE'),
          (this.m = 'NO_SESSION'),
          (this.ja = this.b = null);
      }
      addEventListener(t, e) {
        this.h.addEventListener(t, e);
      }
      removeEventListener(t, e) {
        this.h.removeEventListener(t, e);
      }
      Ub(t) {
        (this.j = new le(t)), ye(this);
      }
      vb() {
        return this.R;
      }
      xa() {
        return this.m;
      }
      requestSession() {
        if (!this.Ba)
          throw Error('Cannot start session before cast options are provided');
        const t = At(),
          i = Promise.resolve(t.N);
        kt(t.N, null, e, void 0), i.catch(e);
        const s = 'NOT_CONNECTED' == this.R;
        return (
          chrome.cast.requestSession(
            (e) => {
              this.bb(e), t.resolve(null);
            },
            (e) => {
              s && ge(this, 'SESSION_START_FAILED', e ? e.code : void 0),
                t.reject(e.code);
            },
          ),
          i
        );
      }
      wb() {
        return this.b;
      }
      mb(t) {
        this.b && this.b.Sa(t);
      }
      Sb(t) {
        this.j
          ? ((this.j.credentialsData = t), ye(this))
          : X(
              'setLaunchCredentialsData was ignored because it was called before setOptions.',
            );
      }
      Pb(t) {
        (this.Ja = t == chrome.cast.ReceiverAvailability.AVAILABLE) &&
          !this.b &&
          this.ja &&
          this.j.resumeSavedSession &&
          chrome.cast.requestSessionById(this.ja),
          be(this);
      }
      Ob(t, e) {
        this.b || e != chrome.cast.ReceiverAction.CAST
          ? this.b && e == chrome.cast.ReceiverAction.STOP
            ? ge(this, 'SESSION_ENDING')
            : t && fe(this.b, t)
          : ge(this, 'SESSION_STARTING');
      }
      bb(t) {
        const e =
          'SESSION_STARTING' == this.m ? 'SESSION_STARTED' : 'SESSION_RESUMED';
        (this.ja = null),
          (this.b = new ve(t, e)),
          t.addUpdateListener(this.Ka.bind(this)),
          ge(this, e);
      }
      Ka() {
        if (this.b)
          switch (this.b.c.status) {
            case chrome.cast.SessionStatus.DISCONNECTED:
            case chrome.cast.SessionStatus.STOPPED:
              ge(this, 'SESSION_ENDED'), (this.ja = this.b.ab), (this.b = null);
              break;
            case chrome.cast.SessionStatus.CONNECTED:
              var t,
                e = this.b,
                i = e.ga,
                a = e.c;
              if ((t = i.applicationId == a.appId && i.name == a.displayName))
                t: if (
                  ((t = i.namespaces),
                  (i = i.Ea(a.namespaces || [])),
                  s(t) && s(i) && t.length == i.length)
                ) {
                  a = t.length;
                  for (var n = 0; n < a; n++)
                    if (t[n] !== i[n]) {
                      t = !1;
                      break t;
                    }
                  t = !0;
                } else t = !1;
              t || ((e.ga = new re(e.c)), e.h.dispatchEvent(new oe(e.ga))),
                fe(e, e.c.receiver),
                e.X != e.c.statusText &&
                  ((e.X = e.c.statusText), e.h.dispatchEvent(new ce(e.X)));
              break;
            default:
              X('Unknown session status ' + this.b.c.status);
          }
        else X('Received session update event without known session');
      }
    };
  u('cast.framework.CastContext', Ce),
    (Ce.prototype.setLaunchCredentialsData = Ce.prototype.Sb),
    (Ce.prototype.endCurrentSession = Ce.prototype.mb),
    (Ce.prototype.getCurrentSession = Ce.prototype.wb),
    (Ce.prototype.requestSession = Ce.prototype.requestSession),
    (Ce.prototype.getSessionState = Ce.prototype.xa),
    (Ce.prototype.getCastState = Ce.prototype.vb),
    (Ce.prototype.setOptions = Ce.prototype.Ub),
    (Ce.prototype.removeEventListener = Ce.prototype.removeEventListener),
    (Ce.prototype.addEventListener = Ce.prototype.addEventListener),
    (Ce.Aa = void 0),
    (Ce.H = function () {
      return Ce.Aa ? Ce.Aa : (Ce.Aa = new Ce());
    }),
    (Ce.getInstance = Ce.H);
  var _e = function () {
      Ce.H().requestSession();
    },
    Ne = function (t, e) {
      t.setAttribute('class', e ? 'cast_caf_state_c' : 'cast_caf_state_d');
    },
    Te = function (t) {
      if ('NO_DEVICES_AVAILABLE' == t.g) t.G.style.display = 'none';
      else
        switch (((t.G.style.display = t.lb), t.g)) {
          case 'NOT_CONNECTED':
            Le(t, !1, 'cast_caf_state_h');
            break;
          case 'CONNECTING':
            Le(t, !1, 'cast_caf_state_h'), t.M || t.Ra();
            break;
          case 'CONNECTED':
            Le(t, !0, 'cast_caf_state_c');
        }
    },
    Ie = function (t, e) {
      (t.na = Ce.H()), (t.Wa = t.Kb.bind(t)), (t.la = []);
      for (let i = 0; 3 > i; i++)
        t.la.push(e.querySelector('#cast_caf_icon_arch' + i));
      (t.ib = e.querySelector('#cast_caf_icon_box')),
        (t.jb = e.querySelector('#cast_caf_icon_boxfill')),
        (t.Ia = 0),
        (t.M = null),
        (t.lb = window.getComputedStyle(t.G, null).display),
        (t.g = t.na.R),
        Te(t),
        t.G.addEventListener('click', _e),
        t.na.addEventListener('caststatechanged', t.Wa);
    },
    Ae = function (t) {
      t.na.removeEventListener('caststatechanged', t.Wa),
        null !== t.M && (window.clearTimeout(t.M), (t.M = null));
    },
    Le = function (t, e, i) {
      for (let i of t.la) Ne(i, e);
      Ne(t.ib, e), t.jb.setAttribute('class', i);
    };
  const we = class {
    constructor(t) {
      this.G = t;
      try {
        this.G.attachShadow({ mode: 'open' }).innerHTML = Qt().getContent();
      } catch (i) {
        t = this.G;
        var e = (function () {
          var t = Qt(void 0);
          if (!n(t)) return O(String(t));
          if (t instanceof ct) {
            if (t.Qa !== ot)
              throw Error('Sanitized content was not of kind HTML.');
            return new k().I(t.toString(), t.Pa || null);
          }
          return (
            E('Soy template output is unsafe for use as HTML: ' + t), O('zSoyz')
          );
        })();
        if (R()) for (; t.lastChild; ) t.removeChild(t.lastChild);
        t.innerHTML = D(e);
      }
    }
    Kb(t) {
      (this.g = t.castState), Te(this);
    }
    Ra() {
      if (((this.M = null), 'CONNECTING' == this.g)) {
        for (let t = 0; 3 > t; t++) Ne(this.la[t], t == this.Ia);
        (this.Ia = (this.Ia + 1) % 3),
          (this.M = window.setTimeout(this.Ra.bind(this), 300));
      }
    }
  };
  var ke = function () {
      document
        .querySelectorAll('button[is=google-cast-button], google-cast-launcher')
        .forEach((t) => De(t));
    },
    De = function (t) {
      (t.u = new we(t)),
        Ie(t.u, t.shadowRoot || t),
        (t.pa = function () {
          Ae(t.u);
        });
    };
  customElements.define
    ? (customElements.define(
        'google-cast-button',
        class extends HTMLButtonElement {
          constructor() {
            super(), (this.u = new we(this));
          }
          connectedCallback() {
            Ie(this.u, this.shadowRoot || this);
          }
          disconnectedCallback() {
            Ae(this.u);
          }
          pa() {}
        },
        { extends: 'button' },
      ),
      customElements.define(
        'google-cast-launcher',
        class extends HTMLElement {
          constructor() {
            super(), (this.u = new we(this));
          }
          connectedCallback() {
            Ie(this.u, this.shadowRoot || this);
          }
          disconnectedCallback() {
            Ae(this.u);
          }
          pa() {}
        },
      ))
    : ('complete' !== document.readyState
        ? window.addEventListener('load', ke)
        : ke(),
      (function () {
        const t = document.createElement.bind(document);
        document.createElement = function (e, i) {
          if (
            'google-cast-launcher' === e ||
            ('button' === e &&
              i &&
              ('google-cast-button' === i || 'google-cast-button' === i.is))
          ) {
            const s = t(e, i);
            return De(s), s;
          }
          return t(...arguments);
        };
      })()),
    u(
      'cast.framework.RemotePlayer',
      class {
        constructor() {
          (this.isMediaLoaded = this.isConnected = !1),
            (this.videoInfo = this.queueData = void 0),
            (this.currentTime = this.duration = 0),
            (this.volumeLevel = 1),
            (this.canControlVolume = !0),
            (this.canSeek = this.canPause = this.isMuted = this.isPaused = !1),
            (this.displayStatus =
              this.title =
              this.statusText =
              this.displayName =
                ''),
            (this.controller =
              this.savedPlayerState =
              this.playerState =
              this.imageUrl =
              this.mediaInfo =
                null),
            (this.isPlayingBreak = !1),
            (this.currentBreakClipNumber = this.numberBreakClips = 0),
            (this.liveSeekableRange =
              this.whenSkippable =
              this.breakClipId =
              this.breakId =
              this.currentBreakClipTime =
              this.currentBreakTime =
                void 0);
        }
      },
    );
  var Oe = class extends ae {
    constructor(t, e, i) {
      super(t), (this.field = e), (this.value = i);
    }
  };
  u('cast.framework.RemotePlayerChangedEvent', Oe);
  var Me = class extends class {
    constructor(t) {
      (t.controller = this),
        (this.a = t),
        (this.v = this.f = this.b = null),
        (this.Xa = this.Lb.bind(this)),
        (this.J = this.Ib.bind(this)),
        (this.K = this.Jb.bind(this)),
        (this.o = 0),
        (this.bc = new Ft(this.ob, 200, this));
    }
    Fa() {
      this.f &&
        (this.o++,
        (this.a.isPaused = !this.a.isPaused),
        this.a.isPaused
          ? this.f.pause(null, this.K, this.J)
          : this.f.play(null, this.K, this.J));
    }
    stop() {
      this.f && (this.o++, this.f.stop(null, this.K, this.J));
    }
    seek() {
      if (this.f) {
        this.v && (clearTimeout(this.v), (this.v = null)), this.o++;
        var t = new chrome.cast.media.SeekRequest();
        (t.currentTime = this.a.currentTime), this.f.seek(t, this.K, this.J);
      }
    }
    Da() {
      this.b &&
        (this.o++,
        (this.a.isMuted = !this.a.isMuted),
        this.b.setReceiverMuted(this.a.isMuted, this.K, this.J));
    }
    Ma() {
      this.bc.qb();
    }
    ob() {
      this.b &&
        (this.o++,
        this.b.setReceiverVolumeLevel(this.a.volumeLevel, this.K, this.J));
    }
    Jb() {
      this.o--, this.A();
    }
    Ib() {
      this.o--, this.f && this.f.getStatus(null, e, e);
    }
    Lb() {
      this.f &&
        ('function' == typeof this.f.getEstimatedBreakTime &&
          (this.a.currentBreakTime = this.f.getEstimatedBreakTime()),
        'function' == typeof this.f.getEstimatedBreakClipTime &&
          (this.a.currentBreakTime = this.f.getEstimatedBreakClipTime()),
        (this.a.currentTime = this.f.getEstimatedTime()),
        (this.v = setTimeout(this.Xa, 1e3)));
    }
    A(t) {
      if (!(0 < this.o))
        if (this.b) {
          this.a.displayName = this.b.displayName || '';
          var e = this.b.statusText || '';
          if (
            ((this.a.displayStatus = e != this.a.displayName ? e : ''),
            !t &&
              this.b.receiver &&
              (t = this.b.receiver.volume) &&
              (null != t.muted && (this.a.isMuted = t.muted),
              null != t.level && (this.a.volumeLevel = t.level),
              (this.a.canControlVolume = 'fixed' != t.controlType)),
            this.f)
          ) {
            if (((t = this.f.breakStatus), (e = this.f), qt(t))) {
              var i = (function (t, e) {
                return (
                  (qt(t) &&
                    ((e && e.breaks) || []).find((e) => e.id === t.breakId)) ||
                  null
                );
              })(t, e.media);
              const s = (function (t, e) {
                return (
                  (qt(t) &&
                    ((e && e.breakClips) || []).find(
                      (e) => e.id === t.breakClipId,
                    )) ||
                  null
                );
              })(t, e.media);
              (this.a.isPlayingBreak = !0),
                (this.a.numberBreakClips =
                  i && Array.isArray(i.breakClipIds)
                    ? i.breakClipIds.length
                    : 0),
                (i =
                  i && s
                    ? 0 > (i = i.breakClipIds.indexOf(s.id))
                      ? 0
                      : i
                    : 0),
                (this.a.currentBreakClipNumber = i),
                (this.a.currentBreakTime =
                  'function' == typeof e.getEstimatedBreakTime
                    ? e.getEstimatedBreakTime()
                    : t.currentBreakTime),
                (this.a.currentBreakClipTime =
                  'function' == typeof e.getEstimatedBreakClipTime
                    ? e.getEstimatedBreakClipTime()
                    : t.currentBreakClipTime),
                (this.a.breakId = t.breakId),
                (this.a.breakClipId = t.breakClipId),
                (this.a.whenSkippable = t.whenSkippable);
            } else
              (this.a.isPlayingBreak = !1),
                (this.a.numberBreakClips = 0),
                (this.a.currentBreakClipNumber = 0),
                (this.a.currentBreakTime = void 0),
                (this.a.currentBreakClipTime = void 0),
                (this.a.breakId = void 0),
                (this.a.breakClipId = void 0),
                (this.a.whenSkippable = void 0);
            (this.a.isMediaLoaded =
              e.playerState != chrome.cast.media.PlayerState.IDLE),
              (this.a.isPaused =
                e.playerState == chrome.cast.media.PlayerState.PAUSED),
              (this.a.canPause =
                0 <=
                e.supportedMediaCommands.indexOf(
                  chrome.cast.media.MediaCommand.PAUSE,
                )),
              this.Y(e.media),
              (this.a.canSeek =
                (!qt(t) ||
                  !(
                    null == t.whenSkippable ||
                    0 > t.whenSkippable ||
                    t.currentBreakClipTime < t.whenSkippable
                  )) &&
                0 <=
                  e.supportedMediaCommands.indexOf(
                    chrome.cast.media.MediaCommand.SEEK,
                  ) &&
                0 != this.a.duration),
              (this.a.currentTime = e.getEstimatedTime()),
              this.v && (clearTimeout(this.v), (this.v = null)),
              e.playerState == chrome.cast.media.PlayerState.PLAYING &&
                (this.v = setTimeout(this.Xa, 1e3));
          } else this.Y(null);
        } else
          (this.a.displayName = ''), (this.a.displayStatus = ''), this.Y(null);
    }
    Y(t) {
      t
        ? ((this.a.duration = t.duration || 0),
          t.metadata &&
            t.metadata.title &&
            (this.a.displayStatus = t.metadata.title))
        : ((this.a.isMediaLoaded = !1),
          (this.a.canPause = !1),
          (this.a.canSeek = !1),
          (this.a.currentTime = 0),
          (this.a.duration = 0),
          (this.a.isPlayingBreak = !1),
          (this.a.numberBreakClips = 0),
          (this.a.currentBreakClipNumber = 0),
          (this.a.currentBreakTime = void 0),
          (this.a.currentBreakClipTime = void 0),
          (this.a.breakId = void 0),
          (this.a.breakClipId = void 0),
          (this.a.whenSkippable = void 0));
    }
    Ka(t) {
      t || (this.f = this.b = null), this.A();
    }
    Va() {
      Kt(this), this.A(!0);
    }
    Eb(t) {
      t || (this.f = null), this.A(!0);
    }
    va(t, e) {
      return e ? (100 * t) / e : 0;
    }
    wa(t, e) {
      return e ? (t * e) / 100 : 0;
    }
    ta(t) {
      return 0 > t
        ? ''
        : [
            ('0' + Math.floor(t / 3600)).substr(-2),
            ('0' + (Math.floor(t / 60) % 60)).substr(-2),
            ('0' + (Math.floor(t) % 60)).substr(-2),
          ].join(':');
    }
  } {
    constructor(t) {
      const e = new ue();
      super(
        (function (t, e) {
          return new window.Proxy(t, {
            set: (t, i, s) =>
              s === t[i] ||
              ((t[i] = s),
              e.dispatchEvent(new Oe(i + 'Changed', i, s)),
              e.dispatchEvent(new Oe('anyChanged', i, s)),
              !0),
          });
        })(t, e),
      ),
        (this.h = e),
        (this.Hb = 0),
        (t = Ce.H()).addEventListener(
          'sessionstatechanged',
          this.Rb.bind(this),
        ),
        (t = t.b) ? Wt(this, t.c) : this.A();
    }
    addEventListener(t, e) {
      this.h.addEventListener(t, e);
    }
    removeEventListener(t, e) {
      this.h.removeEventListener(t, e);
    }
    Rb(t) {
      switch (t.sessionState) {
        case 'SESSION_STARTED':
        case 'SESSION_RESUMED':
          this.a.isConnected = !0;
          const e = t.session && t.session.c;
          e &&
            (Wt(this, e),
            t.session.addEventListener('mediasession', this.Va.bind(this)));
      }
    }
    A(t) {
      const e = Ce.H().b;
      e
        ? (this.a.savedPlayerState = null)
        : this.a.isConnected &&
          (this.a.savedPlayerState = {
            mediaInfo: this.a.mediaInfo,
            currentTime: this.a.currentTime,
            isPaused: this.a.isPaused,
          }),
        super.A(t),
        (this.a.isConnected = !!e),
        (this.a.statusText = (e && e.X) || ''),
        (t = e && e.ua()),
        (this.a.playerState =
          (t && t.playerState) || chrome.cast.media.PlayerState.IDLE),
        t
          ? ((this.a.queueData = t.queueData),
            (this.a.videoInfo = t.videoInfo),
            (this.a.liveSeekableRange =
              'function' == typeof t.getEstimatedLiveSeekableRange
                ? t.getEstimatedLiveSeekableRange()
                : t.liveSeekableRange))
          : ((this.a.queueData = void 0),
            (this.a.videoInfo = void 0),
            (this.a.liveSeekableRange = void 0));
    }
    Y(t) {
      super.Y(t);
      var e = (this.a.mediaInfo = t) && t.metadata;
      t = null;
      let i = '';
      e &&
        ((i = e.title || ''), (e = e.images) && 0 < e.length && (t = e[0].url)),
        (this.a.title = i),
        (this.a.imageUrl = t);
    }
    Fa() {
      super.Fa();
    }
    stop() {
      super.stop();
    }
    seek() {
      super.seek();
    }
    Da() {
      super.Da();
    }
    Ma() {
      super.Ma();
    }
    ta(t) {
      return super.ta(t);
    }
    va(t, e) {
      return super.va(t, e);
    }
    wa(t, e) {
      return super.wa(t, e);
    }
    Yb() {
      let t = Ce.H().b;
      if (t) {
        var e = t.ua();
        e &&
          t &&
          t.sendMessage('urn:x-cast:com.google.cast.media', {
            type: 'SKIP_AD',
            requestId: this.Hb++,
            mediaSessionId: e.mediaSessionId,
          });
      }
    }
  };
  u('cast.framework.RemotePlayerController', Me),
    (Me.prototype.skipAd = Me.prototype.Yb),
    (Me.prototype.getSeekTime = Me.prototype.wa),
    (Me.prototype.getSeekPosition = Me.prototype.va),
    (Me.prototype.getFormattedTime = Me.prototype.ta),
    (Me.prototype.setVolumeLevel = Me.prototype.Ma),
    (Me.prototype.muteOrUnmute = Me.prototype.Da),
    (Me.prototype.seek = Me.prototype.seek),
    (Me.prototype.stop = Me.prototype.stop),
    (Me.prototype.playOrPause = Me.prototype.Fa),
    (Me.prototype.removeEventListener = Me.prototype.removeEventListener),
    (Me.prototype.addEventListener = Me.prototype.addEventListener);
}).call(window);
