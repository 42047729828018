import React, { FC, ReactNode, useLayoutEffect, useMemo, useRef } from 'react';
import { gsap } from 'gsap';
type EventCarouselItemTitleAnimationProps = {
  children: ReactNode;
  isFocusedOrHovered: boolean;
  downAnimation?: boolean;
  isLandscape?: boolean;
};

/**
 * @description Wrapper component passing animation to CarouselEventItem's logo, shortText and date
 */
export const EventCarouselItemTitleAnimation: FC<EventCarouselItemTitleAnimationProps> = ({
  children,
  isFocusedOrHovered,
  downAnimation,
  isLandscape
}) => {
  const stickyComp = useRef(null);
  // Check if we need to show down or up animation
  const yEndPositionValue = useMemo(() => downAnimation ? 0 : -8, [downAnimation]);
  // If landscape then have no animation
  const yEndPosition = useMemo(() => isLandscape ? 0 : yEndPositionValue, [isLandscape, yEndPositionValue]);
  useLayoutEffect(() => {
    const timeline = gsap.timeline({
      trigger: '.event-logo-animation'
    });
    timeline.to(stickyComp.current, {
      y: isFocusedOrHovered ? yEndPosition : 0,
      duration: 0.4
    });
  }, [isFocusedOrHovered, yEndPosition]);
  return <div ref={stickyComp} className="event-logo-animation" data-sentry-component="EventCarouselItemTitleAnimation" data-sentry-source-file="EventCarouselItemTitleAnimation.tsx">
      <>{children}</>
    </div>;
};