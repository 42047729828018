/* eslint-disable import/no-unresolved */
/* eslint-disable unicorn/prefer-module */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
'use client';

import React, { useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import classNames from 'classnames';
import type { KeyOSDRMConfiguration, Player } from 'theoplayer';
import { useGetEnvironmentVariable } from '@/hooks/useGetEnvironmentVariable';
import { PlayerEventAction } from '@/types/Segment';
import { useIsPreviewMode } from '@/utils/contentful/useIsPreviewMode';
import { useEventListener } from '@/utils/useEventListener.web';
import { useMediaSession } from '../../hooks/useMediaSession';
import { usePlayerAnalytics } from '../../hooks/usePlayerAnalytics';
import { userAgentDetails } from '../../utils/user-agent';
import { getContentProtection, getTheoPlayerConfigs } from './constants';
import type { PreviewPageCredentials } from './constants';
import { disablePlayerKeyboardDefaultBehaviour, enablePlayerKeyboardControls } from './functions';
import { useTheoPlayerContext } from './theo-player-context';
import type { THEOplayerError, THEOplayerErrorEvent } from './types';
interface Props {
  title?: string;
  sku?: string;
  posterUrl?: string;
  dashUrl?: string;
  hlsUrl?: string;
  dashCastUrl?: string;
  onError?: (error: THEOplayerError) => void;
  trackingTitle?: string;
  shopifyUserId?: string;
  pipEnabled?: boolean;
  selfHostedVideo?: boolean;
  certUrl?: string;
  chromecastReceiverAppId?: string;
  logTrackPlayEvent?: (duration: number) => void;
  trackPlayerEvents?: (trackAction: PlayerEventAction) => void;
  // The license acquisition lambda uses these credentials to get a license to play DRM content
  previewPageCredentials?: PreviewPageCredentials;
  jwt?: string;
}
const isSSR = typeof document === 'undefined';
const THEOPlayer: FC<Props> = ({
  sku,
  title,
  dashUrl,
  hlsUrl,
  dashCastUrl,
  posterUrl,
  onError,
  trackingTitle,
  shopifyUserId,
  pipEnabled,
  certUrl,
  selfHostedVideo,
  logTrackPlayEvent,
  trackPlayerEvents,
  previewPageCredentials,
  jwt
}) => {
  const playerRef = useRef<HTMLDivElement>(null);
  const [player, setPlayer] = useState<Player>();
  const [isPlayerFocused, setIsPlayerFocused] = useState(true);
  const {
    isMobile
  } = userAgentDetails();
  const [isUiVisible, setIsUiVisible] = useState(false);
  const {
    setIsTheoLoading
  } = useTheoPlayerContext();
  const isPreviewMode = useIsPreviewMode();
  const {
    THEOPLAYER_ENABLE_AC3,
    NODE_ENV,
    YOUBORA_ACCOUNT_CODE,
    YOUBORA_ACCOUNT_CODE_PREVIEW
  } = useGetEnvironmentVariable(['THEOPLAYER_ENABLE_AC3', 'NODE_ENV', 'YOUBORA_ACCOUNT_CODE', 'YOUBORA_ACCOUNT_CODE_PREVIEW']);
  useEffect(() => {
    setIsTheoLoading?.(!selfHostedVideo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const runAsync = async () => {
      if (!isSSR && playerRef.current) {
        // Initialize casting frameworks
        require('./cast-sender.js'); // eslint-disable-line global-require
        require('./cast-framework.js'); // eslint-disable-line global-require

        // Initialize TheoPlayer itsself with configs
        const TheoPlayerLib = require('theoplayer'); // eslint-disable-line global-require
        if (TheoPlayerLib && YOUBORA_ACCOUNT_CODE && YOUBORA_ACCOUNT_CODE_PREVIEW) {
          const playerInstance = new TheoPlayerLib.Player(playerRef.current, {
            ...(await getTheoPlayerConfigs(pipEnabled)),
            analytics: [{
              integration: 'youbora',
              enableAnalytics: true,
              accountCode: isPreviewMode || !!previewPageCredentials ? YOUBORA_ACCOUNT_CODE_PREVIEW : YOUBORA_ACCOUNT_CODE,
              username: shopifyUserId ?? 'Unknown user',
              'content.title': trackingTitle,
              'content.isLive': false
            }]
          });
          if (!selfHostedVideo) playerInstance.autoplay = true;
          setPlayer(playerInstance);
        }
      }
    };
    setTimeout(() => {
      runAsync();
    }, 100);
  }, [shopifyUserId, trackingTitle, pipEnabled, YOUBORA_ACCOUNT_CODE, YOUBORA_ACCOUNT_CODE_PREVIEW]);
  useEffect(() => {
    const runAsync = async () => {
      if (player) {
        player.abr.strategy = isMobile ? 'performance' : 'quality';
        const sourceContentProtection: KeyOSDRMConfiguration | undefined = await getContentProtection({
          sku,
          jwt,
          previewPageCredentials
        });
        const preferredAudioCodecs = ['ec-3'];
        if (THEOPLAYER_ENABLE_AC3 === 'true') {
          preferredAudioCodecs.push('ac-3');
        }

        // Configure the player
        const senderSources = {
          sources: [...(dashUrl ? [{
            src: dashUrl,
            type: 'application/dash+xml',
            contentProtection: sourceContentProtection,
            abr: {
              preferredAudioCodecs
            }
          }] : []), ...(hlsUrl ? [{
            src: hlsUrl,
            type: 'application/x-mpegurl',
            contentProtection: sourceContentProtection
          }] : [])]
        };
        const chromecastSource = {
          sources: [...(dashCastUrl ? [{
            src: dashCastUrl,
            type: 'application/dash+xml',
            contentProtection: sourceContentProtection,
            abr: {
              preferredAudioCodecs
            }
          }] : [])]
        };
        player.source = senderSources;
        if (posterUrl) {
          player.poster = posterUrl;
        }
        if (dashCastUrl) {
          player.cast?.chromecast?.addEventListener('statechange', ({
            state
          }) => {
            if (state === 'connecting') {
              // @ts-expect-error
              player.cast.chromecast.connectionCallback = {
                onStart: () => chromecastSource,
                onStop: () => senderSources,
                onJoin: source => source,
                onLeave: source => source
              };
            }
          });
        }
        player.addEventListener('error', (event: THEOplayerErrorEvent) => {
          setIsTheoLoading?.(false);
          // eslint-disable-next-line no-console
          console.error(event);
          onError?.(event.errorObject);
        });
        player.addEventListener('encrypted', () => {
          // eslint-disable-next-line no-console
          console.log('PLAYER: playing DRM content');
        });

        // It gets triggered when all the data has been loaded and the player is ready to start playing content
        player.addEventListener('loadeddata', () => {
          setIsTheoLoading?.(false);
          setIsUiVisible(true);
        });
        if (!selfHostedVideo) player.play();
        player.pause();
      }

      // On unmounting we want to destroy the player and clean it up
      return () => {
        // destroying the player in DEV mode results in many errors when switching screens
        if (NODE_ENV !== 'development' && player) {
          player.destroy();
        }
      };
    };
    runAsync();
  }, [player, dashUrl, hlsUrl, posterUrl, onError, dashCastUrl, certUrl, logTrackPlayEvent, trackPlayerEvents, isMobile, sku, jwt, setIsTheoLoading, previewPageCredentials]);

  // Custom hook to help sending Segment track logs
  usePlayerAnalytics(player, logTrackPlayEvent, trackPlayerEvents);
  useEventListener('keydown', (event: KeyboardEvent) => {
    disablePlayerKeyboardDefaultBehaviour({
      event
    });
    enablePlayerKeyboardControls({
      player,
      event,
      isPlayerFocused,
      setIsPlayerFocused
    });
  });
  useMediaSession({
    player,
    title: title || 'On Air video',
    subtitle: 'OnAir',
    poster: posterUrl
  });
  return <div data-sentry-component="THEOPlayer" data-sentry-source-file="THEOPlayer.tsx">
      <div className={classNames('theoplayer-wrap', {
      hidden: !isUiVisible
    })}>
        <div className="theoplayer-container video-js theoplayer-skin vjs-16-9 THEOplayer" ref={playerRef} />
      </div>
    </div>;
};
export default THEOPlayer;