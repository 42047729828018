import Image from 'next/image';
import classNames from 'classnames';
import { Carousel, CarouselContent, CarouselItem } from '@/components/-core/carousel';
import { DialogClose, DialogContent, DialogTitle } from '@/components/-core/dialog';
import { ContentfulImage } from '@/types/contentful';
import GradientArea from './gradient-area';
interface Props {
  images: ContentfulImage[];
  startIndex: number;
}
const Lightbox = ({
  images,
  startIndex
}: Props) => {
  return <DialogContent aria-describedby={undefined} data-sentry-element="DialogContent" data-sentry-component="Lightbox" data-sentry-source-file="lightbox.tsx">
      <DialogTitle className="sr-only" data-sentry-element="DialogTitle" data-sentry-source-file="lightbox.tsx">Full size image</DialogTitle>
      <Carousel opts={{
      startIndex
    }} className="lightbox" data-sentry-element="Carousel" data-sentry-source-file="lightbox.tsx">
        <CarouselContent data-sentry-element="CarouselContent" data-sentry-source-file="lightbox.tsx">
          {images.map(({
          asset,
          alt
        }) => <CarouselItem key={asset?.file?.url}>
              <Image height={asset?.height} width={asset?.width} src={asset?.url ?? ''} alt={alt} priority className="max-h-[calc(100vh-160px)] w-full object-contain" />
            </CarouselItem>)}
        </CarouselContent>
        <GradientArea type="previous" data-sentry-element="GradientArea" data-sentry-source-file="lightbox.tsx" />
        <GradientArea type="next" data-sentry-element="GradientArea" data-sentry-source-file="lightbox.tsx" />
        <DialogClose className={classNames('lightbox__close', 'flex h-[48px] w-[48px] -translate-y-[calc(100%+16px)] items-center justify-center rounded-full bg-[--shade-2] text-[24px] text-[--shade-6] transition-colors duration-200 hover:bg-[--shade-3]')} data-sentry-element="DialogClose" data-sentry-source-file="lightbox.tsx" />
      </Carousel>
    </DialogContent>;
};
export default Lightbox;