import React, { FC, useCallback, useState } from 'react';
import Image from 'next/image';
import classNames from 'classnames';
import { ContentfulEvent, ContentfulTile } from '@/types/contentful';
import { sharedAnalyticsProperties } from '@/utils/analytics';
import { trackProduct } from '@/utils/segment-analytics';
import { BackgroundImage } from '../BackgroundImage';
import LinkInternal from '../LinkInternal';
import { EventCarouselItemTitleAnimation } from './EventCarouselItemTitleAnimation';
interface EventCarouselTileProps {
  tile: ContentfulTile;
  passedRef?: React.RefObject<HTMLAnchorElement>;
  lastItem?: boolean;
  landscape?: boolean;
  small?: boolean;
}
export const EventCarouselTile: FC<EventCarouselTileProps> = ({
  tile,
  passedRef,
  lastItem,
  landscape,
  small
}) => {
  const [isFocusedOrHovered, setIsFocusedOrHovered] = useState(false);
  const eventInfoImage = tile?.logo;
  const shortTitle = tile?.shortTitle;
  const handleProductClick = () => {
    // Force event to be of type ContentfulEvent.
    const event = {
      shopifyProductId: tile.id,
      title: tile.title,
      slug: tile.title
    } as ContentfulEvent;
    trackProduct({
      ...sharedAnalyticsProperties,
      trackCallback: window?.analytics?.track,
      action: 'Tile Clicked',
      url: window.location.href,
      event
    });
  };
  const handleSetIsFocusedOrHovered: React.FocusEventHandler<HTMLAnchorElement> & React.MouseEventHandler<HTMLAnchorElement> = useCallback((e): void => {
    setIsFocusedOrHovered(e.type === 'focus' || e.type === 'mouseover');
  }, [setIsFocusedOrHovered]);
  return <LinkInternal className={classNames('carousel__item', {
    'carousel__item--last': lastItem,
    'carousel__item--small': small
  })} to={`/${tile?.slug}/`} innerRef={passedRef} onClick={handleProductClick} onBlur={handleSetIsFocusedOrHovered} onFocus={handleSetIsFocusedOrHovered} onMouseOver={handleSetIsFocusedOrHovered} onMouseLeave={handleSetIsFocusedOrHovered} data-sentry-element="LinkInternal" data-sentry-component="EventCarouselTile" data-sentry-source-file="EventCarouselTile.tsx">
      <div className="event-content">
        <BackgroundImage className={classNames('event-content__background d-flex flex-column-reverse', {
        'flex-column': landscape,
        'flex-column-reverse': !landscape
      })} imageUrl={tile.landscapeImage?.url ?? ''} height={584} ariaLabel={tile.landscapeImage.alt} data-sentry-element="BackgroundImage" data-sentry-source-file="EventCarouselTile.tsx">
          <div className={classNames('event-content__container d-flex justify-content-center', {
          'flex-column-reverse': true,
          'mb-1 p-2': !landscape,
          'p-2': landscape
        })}>
            {tile?.sublabel && !landscape && <p className={classNames('caption-text text-shadow-light event__card-subline', {
            'mt-1 mb-4 mb-xl-7 mb-lg-6': !landscape && !isFocusedOrHovered,
            'mt-0 mb-2 mb-xl-5 mb-lg-4': !landscape && isFocusedOrHovered,
            'd-none': small || landscape
          })}>
                {tile?.sublabel}
              </p>}

            <p className={classNames('event-content__short-text text-shadow-light body-title mb-1', {
            'event-content__short-text-no-date': false,
            'd-none': !isFocusedOrHovered || landscape
          })}>
              {shortTitle}
            </p>

            {landscape ? <h6 className="caption-text text-shadow-light">{tile.title}</h6> : <EventCarouselItemTitleAnimation isFocusedOrHovered={isFocusedOrHovered}>
                <div className="logo-img">
                  {tile.logo && <Image style={{
                objectFit: 'contain'
              }} src={(eventInfoImage as any)?.url ?? ''} fill={true} alt={tile.landscapeImage.alt} />}
                </div>
              </EventCarouselItemTitleAnimation>}
          </div>
        </BackgroundImage>
      </div>
    </LinkInternal>;
};