import { FC } from 'react';
import Image from 'next/image';
import classNames from 'classnames';
import { ContentfulContentBlock, ContentfulImage } from '@/types/contentful';
import { Dialog, DialogTrigger } from '../-core/dialog';
import Lightbox from '../-ui/lightbox/lightbox';
interface Props {
  image?: ContentfulImage;
  position?: ContentfulContentBlock['imagePosition'];
  canShowFullImage?: boolean;
}
const ContentBlockImage = ({
  image,
  position,
  canShowFullImage = false
}: Props) => {
  const url = image?.asset?.url;
  if (!image?.alt || !url) return null;
  const Wrapper = canShowFullImage ? DialogTrigger : 'div';
  const imageElement = <Wrapper className={classNames('content-block__image mb-lg-0 col-12 col-lg-6 d-flex align-items-center mb-5', {
    'justify-content-center': position === 'center',
    'pe-lg-5': url && position === 'left',
    'ps-lg-5': url && position === 'right'
  })} style={{
    width: '100%',
    flex: 1
  }}>
      <Image height={696} width={696} className="w-100" src={url} alt={image.alt} style={{
      objectFit: 'contain'
    }} />
    </Wrapper>;
  if (canShowFullImage) {
    return <Dialog>
        {imageElement}
        <Lightbox images={[image]} startIndex={0} />
      </Dialog>;
  }
  return imageElement;
};
export default ContentBlockImage;