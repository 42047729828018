import React, { FC } from 'react';
import classNames from 'classnames';
import { ExtendedContentfulLink } from '@/types/link';
import { LinkType } from '@/types/Notification';
import LinkExternal from '../LinkExternal';
interface Props {
  links: ExtendedContentfulLink[];
  alignImageCenter?: boolean;
  socialMediaIconsTitle?: string;
}
export const ContentBlockIcons: FC<Props> = ({
  links,
  alignImageCenter,
  socialMediaIconsTitle
}) => {
  const hasTitle = !!socialMediaIconsTitle && socialMediaIconsTitle.length > 0;
  return <div className="content-block__link-area mb-2" data-sentry-component="ContentBlockIcons" data-sentry-source-file="ContentBlockIcons.tsx">
      {hasTitle ? <h4 className="content-block__social-media-title mb-1 mt-0 body-text uppercase">
          {socialMediaIconsTitle}
        </h4> : undefined}

      <div className={classNames('d-flex', {
      'justify-content-center': alignImageCenter,
      'align-items-center': alignImageCenter,
      'text-center': alignImageCenter
    })}>
        {links.map(({
        href,
        icon,
        text,
        onClick,
        shouldFollow,
        shouldOpenInNewTab
      }) => <LinkExternal key={href} shouldFollow={shouldFollow} shouldOpenInNewTab={shouldOpenInNewTab} to={href} icon={icon?.type} className={classNames('body-text me-3', {
        'mt-0': !hasTitle
      })} type={LinkType.LINK_MAIN} onClick={onClick} altText={text} />)}
      </div>
    </div>;
};