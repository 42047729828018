'use client';

import { useEffect, useMemo, useState } from 'react';

import { DEFAULT_COUNTRY_CODE, Event, EventVariant } from '@/types/contentful';
import { useEventConfigurator } from '@/utils/eventConfigurator';
import { pushToElevarDataLayer } from '@/utils/pushToElevarDataLayer';
import {
  trackCartAddOrRemove,
  trackViewEventDetails,
} from '@/utils/segment-analytics';
import { parseProductVariantShopifyStringFromID } from '@/utils/shopifyParser';
import {
  CART_ATTRIBUTE,
  CART_ITEM_TYPE,
  CART_LINE_ATTRIBUTE,
} from '../components/cart/constants';
import ROUTES from '../constants/routes';
import {
  addCartLinesAction,
  createCartAction,
} from '../state/actions/cartActions';
import {
  useAppDispatch,
  useAppSelector,
  useCountryCodeSelector,
} from '../state/hooks';
import type { CartState } from '../state/reducers/cartSlice';
import { sharedAnalyticsProperties } from '../utils/analytics';
import { navigate } from '../utils/navigation';

export const useBuyTicket = (event: Event, chosenVariant?: EventVariant) => {
  const [referrerUrl, setReferrerUrl] = useState<string | null>(null);

  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);
  const customerAccessToken = useAppSelector(
    (state) => state.auth.token?.customerAccessToken,
  );
  const { selectedVariant: defaultVariant } = useEventConfigurator({
    variants: event?.variants || [],
  });
  const selectedVariant = chosenVariant ?? defaultVariant;
  const customerCountryCode = useCountryCodeSelector() || DEFAULT_COUNTRY_CODE;
  const customerInfo = useAppSelector(
    (state) => state.auth.token?.customerInfo,
  );
  const { cart, status: cartStatus }: CartState = useAppSelector(
    (state) => state.cartSlice,
  );

  const skusInTheCart: string[] = useMemo(
    () => cart?.lines.edges.map(({ node }) => node.merchandise.sku) || [],
    [cart],
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const storedSlug = localStorage.getItem('cartSlug');
      if (storedSlug) setReferrerUrl(storedSlug);
    }
  }, []);

  const addItemToCart = () => {
    pushToElevarDataLayer({
      eventName: 'dl_add_to_cart',
      auth: auth.token,
      ecommerce: {
        currencyCode: 'EUR',
        add: {
          actionField: {
            list: window.location.pathname,
          },
          products: [
            {
              id: event.id,
              name: event.title,
              brand: 'OnAir', //Vendor
              category: 'Dummy category', // Genre
              variant: selectedVariant?.title || event.title,
              price: selectedVariant?.price?.eur?.toString(),
              compare_at_price:
                selectedVariant?.compareAtPrice?.eur?.toString() || '0.0',
              quantity: '1',
              list: referrerUrl,
              product_id: event.shopifyProductId,
              variant_id: selectedVariant?.shopifyProductVariantId!,
              image: event.poster.asset?.url,
              url: window.location.href,
            },
          ],
        },
      },
    });

    // TODO: remove all segment events
    trackViewEventDetails({
      ...sharedAnalyticsProperties,
      trackCallback: window?.analytics?.track,
      eventDetailsAction: 'Add To Cart',
      countryCode: customerCountryCode,
      selectedVariant,
      event,
    });

    trackCartAddOrRemove({
      ...sharedAnalyticsProperties,
      trackCallback: window?.analytics?.track,
      cartAction: 'Product Added',
      event,
      selectedVariant,
      countryCode: customerCountryCode,
      url: window.location.href,
      cartId: `${cart?.id}`,
    });
    if (selectedVariant && skusInTheCart.includes(selectedVariant.sku)) {
      navigate(ROUTES.cart);
    } else if (selectedVariant?.shopifyProductVariantId) {
      const lines = [
        {
          merchandiseId: btoa(
            parseProductVariantShopifyStringFromID(
              selectedVariant.shopifyProductVariantId,
            ),
          ),
          quantity: 1,
          attributes: [
            {
              key: CART_LINE_ATTRIBUTE.CART_ITEM_TYPE,
              value: CART_ITEM_TYPE.EVENT,
            },
            {
              key: CART_LINE_ATTRIBUTE.LINE_CREATION_DATE,
              value: new Date().toISOString(),
            },
          ],
        },
      ];
      dispatch(
        cart?.id
          ? addCartLinesAction({
              cartId: cart.id,
              lines,
              countryCode: customerCountryCode,
            })
          : createCartAction({
              createCartVariables: {
                customerAccessToken,
                email: customerInfo?.email,
                lines,
                countryCode: customerCountryCode,
                attributes: [
                  {
                    key: CART_ATTRIBUTE.CART_CREATED_COUNTRY,
                    value: customerCountryCode,
                  },
                ],
              },
            }),
      );
    }
  };

  if (!event) return {};

  return {
    addItemToCart,
    cartStatus,
  };
};
