/* eslint-disable no-param-reassign */
import { useEffect, useState } from 'react';

import { Player } from 'theoplayer';

export const initMediaSessionAPI = ({
  player,
  metadata,
}: {
  player: Player;
  metadata: {
    title: string;
    artist: string;
    artwork?: { src: string; sizes: string; type: string }[];
  };
}) => {
  const { mediaSession } = navigator;

  if (mediaSession) {
    mediaSession.metadata = new MediaMetadata(metadata);
    mediaSession.setActionHandler('play', () => {
      player.play();
    });
    mediaSession.setActionHandler('pause', () => {
      player.pause();
    });
    mediaSession.setActionHandler('seekto', ({ seekTime }) => {
      if (player.currentTime && seekTime) player.currentTime = seekTime;
    });
    navigator.mediaSession.setActionHandler('seekbackward', () => {
      if (player.currentTime && player.currentTime - 10 > 0)
        player.currentTime -= 10;
    });
    navigator.mediaSession.setActionHandler('seekforward', () => {
      if (player.currentTime && player.currentTime + 10 < player.duration)
        player.currentTime += 10;
    });
  }

  const updateMediaSessionState = () => {
    if (!navigator.mediaSession || Number.isNaN(player.duration)) {
      return;
    }
    const playbackState: MediaSessionPlaybackState = player.paused
      ? 'paused'
      : 'playing';
    mediaSession.playbackState = playbackState;
    const positionState = {
      duration: player.duration,
      playbackRate: player.playbackRate,
      currentTime: player.currentTime,
    };
    mediaSession.setPositionState(positionState);
  };

  player.addEventListener(
    ['playing', 'pause', 'durationchange', 'timeupdate', 'ratechange'],
    updateMediaSessionState,
  );
};

export const useMediaSession = ({
  player,
  title,
  poster,
  subtitle,
}: {
  player?: Player;
  title: string;
  poster?: string;
  subtitle: string;
}) => {
  const [firstPlaying, setFirstPlaying] = useState(true);

  useEffect(() => {
    if (player && firstPlaying) {
      initMediaSessionAPI({
        player,
        metadata: {
          title,
          artist: subtitle,
          artwork: poster
            ? [
                {
                  src: `https:${poster}`,
                  sizes: '256x256',
                  type: 'image/jpg',
                },
                {
                  src: `https:${poster}`,
                  sizes: '384x384',
                  type: 'image/jpg',
                },
                {
                  src: `https:${poster}`,
                  sizes: '512x512',
                  type: 'image/jpg',
                },
              ]
            : undefined,
        },
      });
      /**
       * The Media Session API is fired up on the second playing event (as opposed to the first, which would be expected behaviour).
       * One simple workaround is to simply pause and resume the stream immediately.
       */
      player.pause();
      setTimeout(() => {
        player.play();
        if (!player.autoplay) player.pause();
      }, 100);
      setFirstPlaying(false);
    }
  }, [player, poster, firstPlaying, title, subtitle]);
};
