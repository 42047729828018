import classNames from 'classnames';
import { CarouselNext, CarouselPrevious } from '@/components/-core/carousel';
import ChevronButton from '../chevron-button/chevron-button';
interface Props {
  type: 'previous' | 'next';
}
const GradientArea = ({
  type
}: Props) => {
  const CarouselButton = type === 'previous' ? CarouselPrevious : CarouselNext;
  return <CarouselButton className={classNames('lightbox__gradient', 'touchscreen:hidden absolute bottom-0 top-0 w-[80px] from-transparent from-[0.83%] to-[--shade-6] to-80% hover:cursor-pointer focus-visible:outline-none disabled:hidden md:w-[166px]', {
    'left-[0] bg-gradient-to-l': type === 'previous',
    'right-[0] bg-gradient-to-r': type === 'next'
  })} data-sentry-element="CarouselButton" data-sentry-component="GradientArea" data-sentry-source-file="gradient-area.tsx">
      <ChevronButton as="span" direction={type === 'previous' ? 'left' : 'right'} className={classNames('absolute top-[50%] z-[51] translate-y-[-50%]', {
      'left-[16px]': type === 'previous',
      'right-[16px]': type === 'next'
    })} data-sentry-element="ChevronButton" data-sentry-source-file="gradient-area.tsx" />
    </CarouselButton>;
};
export default GradientArea;