/**
 *
 * @param embeddedPlaylist  A stringified iframe
 * @param prefixString Iframe's src prefix eg: 'src="https://open.spotify.com/embed/'
 * @returns The source of the stringified iframe or the prefix value in case the string is not valid
 */
export const getIframeSource = (
  embeddedPlaylist: string,
  prefixString: string,
): string => {
  // Split the string in to an array of strings
  const substrings = embeddedPlaylist.split(' ');

  if (substrings.length) {
    // Find the src substring based on the provided prefix value
    const sourceString = substrings.find((substring) =>
      substring.includes(prefixString),
    );

    // If there is no valid value return the provided prefix
    return sourceString ?? prefixString;
  }

  return prefixString;
};

/**
 *
 * @param embeddedPlaylist A stringified iframe
 * @returns In case of an invalid id the string 'invalid_id' which will render a 'broken' Spotify playlist
 * else the id of Spotify's playlist eg: playlist/37i9dQZF1E34YSAadcCxI0
 */
export const getSpotifyEmbeddedPlaylistId = (
  embeddedPlaylist: string,
): string => {
  const SPOTIFY_SRC_PREFIX = 'src="https://open.spotify.com/embed/';

  // Get the src of the iframe and remove Spotify's prefix
  const formattedIframeSource = getIframeSource(
    embeddedPlaylist,
    SPOTIFY_SRC_PREFIX,
  ).replace(SPOTIFY_SRC_PREFIX, '');

  // If the src is valid split it on the query and return the first part
  if (formattedIframeSource?.length) {
    const formattedSubstrings = formattedIframeSource.split('?');
    const [playlistId] = formattedSubstrings;

    return playlistId;
  }

  return 'invalid_id';
};
