//@ts-nocheck
import React, { ReactNode } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Block, Document as ContentfulDocument, Inline, INLINES } from '@contentful/rich-text-types';
import { ContentfulGraphqlRichDescription } from '../types/contentful';

// Some content-types have id's that don't match the name
// exactly. The following lookup map is used to translate
// these "deviant" Content-types to their corresponding id's
const contentfulDeviantContentTypes: {
  [key: string]: string;
} = {
  ContentfulSellingPoint: 'eventFeature',
  ContentfulEventHero: 'hero',
  ContentfulHero: 'genericHero',
  ContentfulEventVariantOption: 'eventOptions'
};

/**
 * Normalizes the graphql response data and extracts the `__type`
 * from the `__typename` field. By default, the `__typename` field
 * does not exist in the graphql data, and it therefore has to be
 * explicitly requested when the type information is needed (e.g. when
 * requesting blocks for flexible pages or events).
 * See `__typename` in `queries.ts`.
 */
export function normalizeContentfulGraphQLNode(node: any) {
  if (node === undefined || node === null) return;
  if (Array.isArray(node)) {
    node.forEach(normalizeContentfulGraphQLNode);
  } else if (typeof node === 'object') {
    Object.entries(node).forEach(([key, value]) => {
      if (key === '__typename') {
        const val = value as string;
        // eslint-disable-next-line no-param-reassign, no-underscore-dangle
        node.__type = contentfulDeviantContentTypes[val] || val.substring(10, 11).toLowerCase() + val.substring(11);
        // eslint-disable-next-line no-param-reassign, no-underscore-dangle
        delete node.__typename;
      } else {
        normalizeContentfulGraphQLNode(value);
      }
    });
  }
}
export function renderContentfulDescription(description?: ContentfulGraphqlRichDescription | ContentfulDocument | string): React.ReactNode | undefined {
  if (!description) return undefined;
  if (typeof description === 'string') {
    return description;
  }
  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: (node: Block | Inline, children: ReactNode) => {
        const {
          data
        } = node;
        const linkIsMatchingDomain = data.uri.startsWith(process.env.SITE_URL) || data.uri.startsWith('https://onair.events');
        return <a target={`${linkIsMatchingDomain ? '_self' : '_blank'}`} rel={`${linkIsMatchingDomain ? undefined : 'noopener noreferrer nofollow'}`} href={data.uri}>
            {children}
          </a>;
      }
    }
  };
  if ((description as ContentfulGraphqlRichDescription)?.json) {
    try {
      return documentToReactComponents(JSON.parse(
      // @ts-ignore
      (description as ContentfulGraphqlRichDescription)?.json), options);
    } catch (e) {
      return documentToReactComponents(
      // @ts-ignore
      (description as ContentfulGraphqlRichDescription)?.json, options);
    }
  }
  if (description) {
    // @ts-ignore
    return documentToReactComponents(
    // @ts-ignore
    (description as ContentfulGraphqlRichDescription)?.json, options);
  }
  return undefined;
}