'use client';

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { FC, useEffect } from 'react';
import { ContentfulEventCarousel } from '@/types/contentful';
import EventCarouselComponent from './EventCarouselComponent';
const EventCarousel: FC<{
  carousel: ContentfulEventCarousel;
  small?: boolean;
  isFirstBlock?: boolean;
}> = ({
  carousel,
  small = false,
  isFirstBlock
}) => {
  if (!carousel.events.length) return null;
  useEffect(() => {
    // Extract the slug from the URL
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    let slug = url.pathname;
    // If the slug is blank or only the base URL, default to '/'
    if (!slug || slug === '/') {
      slug = '/';
    }
    // Store the slug in localStorage
    localStorage.setItem('slug', slug);
    localStorage.setItem('cartSlug', slug);
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  return <EventCarouselComponent carousel={carousel} small={small} isFirstBlock={isFirstBlock} data-sentry-element="EventCarouselComponent" data-sentry-component="EventCarousel" data-sentry-source-file="index.tsx" />;
};
export default EventCarousel;