/* eslint-disable no-param-reassign */
import type { Player } from 'theoplayer';

import { clamp } from '../../utils/clamp';

const togglePlay = (player: Player) => {
  if (player.paused) player.play();
  else {
    player.pause();
  }
};

/**
 * Changes the volume based on the amount. Could be both negative or positive.
 * If the player is muted, it will be unmuted.
 * @param player THEOPlayer's player instance
 * @param amount Number in between 0 and 1
 */
const changeVolume = (player: Player, amount: number) => {
  if (player?.muted) player.muted = false;

  player.volume = clamp(player.volume + amount, 0, 1);
};

const toggleSound = (player: Player) => {
  if (player?.muted === true) player.muted = false;
  else if (player?.muted === false) player.muted = true;
};

/**
 * It seeks forward or backward depending of seekTime being positive or negative
 * @param player THEOPlayer's player instance
 * @param seekAmount Number in seconds
 */
const seekTime = (player: Player, seekAmount: number) => {
  if (player?.currentTime) player.currentTime += seekAmount;
};

const toggleFullscreen = (player: Player) => {
  if (player.presentation.currentMode === 'fullscreen') {
    player.presentation.requestMode('inline');
  } else {
    player.presentation.requestMode('fullscreen');
  }
};

/**
 * Add event listeners to the volume bar to detect when the focus is on it.
 * This is used to prevent the player from seeking when the user is using the
 * arrow keys to change the volume.
 *
 * @param isPlayerFocused Boolean that indicates if the focus is on the player
 * @param setFocusOnPlayer Function to set the focusOnPlayer state
 */
const setArrowControlFocus = (
  isPlayerFocused: boolean,
  setFocusOnPlayer: (focusOnPlayer: boolean) => void,
) => {
  document?.querySelector('.vjs-volume-bar')?.addEventListener(
    'focus',
    () => {
      if (isPlayerFocused) setFocusOnPlayer(false);
    },
    false,
  );

  document?.querySelector('.vjs-volume-bar')?.addEventListener(
    'blur',
    () => {
      if (!isPlayerFocused) setFocusOnPlayer(true);
    },
    false,
  );
};

export const enablePlayerKeyboardControls = ({
  player,
  event,
  isPlayerFocused,
  setIsPlayerFocused,
}: {
  player?: Player;
  event: KeyboardEvent;
  isPlayerFocused: boolean;
  setIsPlayerFocused: (isPlayerFocused: boolean) => void;
}) => {
  setArrowControlFocus(isPlayerFocused, setIsPlayerFocused);
  if (player && event) {
    switch (event.keyCode) {
      case 32: // Space
        togglePlay(player);
        break;
      case 77: // M
        toggleSound(player);
        break;
      case 70: // F
        toggleFullscreen(player);
        break;
      case 38: // Up
        changeVolume(player, 0.1);
        break;
      case 40: // Down
        changeVolume(player, -0.1);
        break;
      case 37: // Left
        if (!isPlayerFocused) return;
        seekTime(player, -5);
        break;
      case 39: // Right
        if (!isPlayerFocused) return;
        seekTime(player, 5);
        break;
      default:
        break;
    }
  }
};

export const disablePlayerKeyboardDefaultBehaviour = ({
  event,
}: {
  event: KeyboardEvent;
}) => {
  const keysToBePreventedByDefault = [32, 38, 40]; // Space, Up, Down
  if (event && keysToBePreventedByDefault.includes(event.keyCode)) {
    event.preventDefault();
  }
};
