'use client';

import React, { useMemo, useState } from 'react';
import type { ReactNode } from 'react';
import type { TheoPlayerContextProps } from './types';
export const TheoPlayerContext = React.createContext<TheoPlayerContextProps>({
  isTheoLoading: undefined,
  setIsTheoLoading: undefined
});
export const TheoPlayerContextWrapper = ({
  children
}: {
  children: ReactNode;
}) => {
  const [isTheoLoading, setIsTheoLoading] = useState(false);
  const value = useMemo(() => ({
    isTheoLoading,
    setIsTheoLoading
  }), [isTheoLoading, setIsTheoLoading]);
  return <TheoPlayerContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="TheoPlayerContextWrapper" data-sentry-source-file="TheoPlayerContext.tsx">
      {children}
    </TheoPlayerContext.Provider>;
};