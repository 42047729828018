'use client';

import type { FC } from 'react';
import { ContentfulContentBlock } from '@/types/contentful';
import { useContentfulButtonProps } from '../../hooks/useContentfulButtonProps';
import { renderContentfulDescription } from '../../utils/contentful';
import { generateShareInSocialsLinks } from '../../utils/social';
import ContentBlockComponent from './ContentBlockComponent';
const ContentBlock: FC<{
  contentBlock: ContentfulContentBlock;
  showTransparentBackground?: boolean;
  isFirstBlock?: boolean;
  canShowFullImage?: boolean;
}> = ({
  contentBlock,
  showTransparentBackground,
  isFirstBlock,
  canShowFullImage
}) => {
  const contentfulButtonProps = useContentfulButtonProps(contentBlock.button);
  return <ContentBlockComponent isFirstBlock={isFirstBlock} event={contentBlock.event} key={contentBlock.title} title={contentBlock.title} subtitle={contentBlock.subtitle} showAppStoreBanners={contentBlock.showAppStoreBanners} showTransparentBackground={showTransparentBackground} showTitle={contentBlock.showTitle} description={renderContentfulDescription(contentBlock.description)} image={contentBlock.image} canShowFullImage={canShowFullImage} buttonProps={contentfulButtonProps} imagePosition={contentBlock.imagePosition} links={[...(contentBlock.shouldShowSocialButtons ? generateShareInSocialsLinks() : []), ...(contentBlock.links || [])]} data-sentry-element="ContentBlockComponent" data-sentry-component="ContentBlock" data-sentry-source-file="index.tsx" />;
};
export default ContentBlock;