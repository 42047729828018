import { useMemo } from 'react';
import { mapContentfulButtonTypeToEnum } from '@/enums/ButtonType';
import { ButtonProps } from '../types/button';
import { ContentfulButton } from '../types/contentful';
import { goToRoute } from '../utils/navigation';

/**
 * Simple hook accepting contentfulButton props as an argument
 * and returns the correct button props whapping the method with a useMemo hook
 * in order to avoid unnecessary rerenders
 *
 * @returns The button props as extracted from ContentfulButton
 */
export const useContentfulButtonProps = (contentfulButton?: ContentfulButton): ButtonProps | undefined => {
  const getContentfulButtonProps = useMemo(() => contentfulButton?.text ? {
    onClick: (event?: React.MouseEvent<Element>) => {
      // prevent navigating using the href and use onClick instead
      event?.preventDefault();
      goToRoute(contentfulButton?.href, contentfulButton?.shouldOpenLinkInNewTab);
    },
    children: contentfulButton.text,
    ariaLabel: contentfulButton?.ariaLabel,
    type: mapContentfulButtonTypeToEnum[contentfulButton?.type],
    href: contentfulButton?.href,
    isBuyButton: contentfulButton?.isBuyButton
  } : undefined, [contentfulButton?.ariaLabel, contentfulButton?.href, contentfulButton?.isBuyButton, contentfulButton?.shouldOpenLinkInNewTab, contentfulButton?.text, contentfulButton?.type]);
  return getContentfulButtonProps;
};