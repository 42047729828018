export const AVAILABLE_LANGUAGES = ['en', 'nl'];

export const AVAILABLE_NAMESPACES = ['global', 'homepage'];

export const NOT_SELECT_EVENT_CAROUSEL_ID = [
  '2m0nl5TkhlShNYfTDXZROj',
  '5UQMTytj1lg98GOA2Mf9dS',
  '7IAwdD5b6YTvVCA7XS3sJR',
  '3Ou9fshLinMxNOFySoUDLY',
];
