'use client';

import React, { FC } from 'react';
import classNames from 'classnames';
import { ButtonType } from '@/enums/ButtonType';
import { ContentfulButton, ContentfulDocument, ContentfulGraphqlRichDescription } from '@/types/contentful';
import { renderContentfulDescription } from '../../utils/contentful';
import { goToRoute } from '../../utils/navigation';
import Button from '../Button';
import { EmbeddedSpotifyPlayer } from './EmbeddedSpotifyPlayer';
import { getSpotifyEmbeddedPlaylistId } from './utils';
type Props = {
  embeddedPlaylist: string;
  callToActionButton?: ContentfulButton;
  title?: string;
  text?: ContentfulGraphqlRichDescription | ContentfulDocument | string;
  isFirstBlock?: boolean;
};
export const SpotifyPlayerBlock: FC<Props> = ({
  embeddedPlaylist,
  callToActionButton,
  title = '',
  text,
  isFirstBlock
}) => {
  const spotifyPlayerId = getSpotifyEmbeddedPlaylistId(embeddedPlaylist);
  const playerText = renderContentfulDescription(text);
  const buttonProps = callToActionButton?.text ? {
    onClick: () => goToRoute(callToActionButton?.href, callToActionButton?.shouldOpenLinkInNewTab),
    children: callToActionButton?.text,
    ariaLabel: callToActionButton?.ariaLabel,
    type: ButtonType.OUTLINE_SECONDARY
  } : undefined;
  const hasTextInPlaylist = spotifyPlayerId && (title?.length || playerText || buttonProps?.children);
  return <section className={classNames('content-block content-block--show-transparent-background', {
    'first-block': isFirstBlock
  })} data-sentry-component="SpotifyPlayerBlock" data-sentry-source-file="SpotifyPlayerBlock.tsx">
      <div className="container container--vertical-padding">
        <div className="flex-lg-row-reverse row my-md-0">
          {/* Player's title, text and action button */}
          <div className="d-flex container--padding-vertical px-1 px-lg-7 flex-column my-auto col-12 col-lg-6">
            {!!title?.length && <h3 className="title">{title}</h3>}
            {playerText && <div className="description">{playerText}</div>}
            {buttonProps?.children && <Button className="content-block__call-to-action mb-2" onClick={buttonProps.onClick} type={buttonProps.type} ariaLabel={buttonProps.ariaLabel}>
                {buttonProps.children}
              </Button>}
          </div>
          {/* Spotify embedded player */}
          <div className={classNames('mb-0 mt-2 mt-lg-0 mb-lg-0 col-12 d-flex', {
          'col-lg-6': hasTextInPlaylist
        })}>
            <EmbeddedSpotifyPlayer playerId={spotifyPlayerId} data-sentry-element="EmbeddedSpotifyPlayer" data-sentry-source-file="SpotifyPlayerBlock.tsx" />
          </div>
        </div>
      </div>
    </section>;
};